import {
  CardProps,
  Card,
  styled,
  Typography,
  Box,
  BoxProps,
  ButtonProps,
} from "@mui/material";
import { PrimaryButton } from "./PrimaryButton";
import { format } from "date-fns";

const SmallButton = styled(PrimaryButton)<ButtonProps>(({ theme }) => ({
  marginTop: 10,
  fontSize: 14,
  fontWeight: 600,
  paddingTop: 5,
  [theme.breakpoints.up("sm")]: {
    height: 40,
  },
  [theme.breakpoints.down("sm")]: {
    width: "85%",
  },
}));

const ContainerCard = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: 25,
  padding: 0,
  display: "flex",
  borderColor: "#0C46FF",
  borderWidth: 1,
  borderStyle: "solid",
  marginBottom: 30,
  width: "90%",
  [theme.breakpoints.up("sm")]: {
    maxWidth: 750,
    minWidth: 600,
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const InfoCardContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: 10,
  marginTop: 20,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const DownloadBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,

  [theme.breakpoints.down("sm")]: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

const InfoCard = ({ value, label }) => {
  return (
    <div
      style={{
        backgroundColor: "#d0daff",
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <span style={{ fontSize: 12, color: "#5F616E" }}>{label}</span>
      <div style={{ fontSize: 17, color: "#002A6D", fontWeight: 500 }}>
        {value}
      </div>
    </div>
  );
};

const WorkshopCard = ({ data, baseColor = "#0C46FF", onClick }) => {
  return (
    <ContainerCard style={{ borderColor: baseColor, margin: "0 auto 20px" }}>
      <div
        style={{
          backgroundColor: baseColor,
          color: "#FFFFFF",
          padding: 20,
          flex: 3,
        }}
      >
        <div>Taller:</div>
        <Typography variant="h5" gutterBottom style={{ fontWeight: 500 }}>
          {data.name_workshop}
        </Typography>
        <InfoCardContainer>
          <InfoCard label="Folio" value={data.codigo} />
          <InfoCard
            label="Emisión"
            value={format(
              new Date(data.created.replace(/-/gi, "/")),
              "dd/MM/yyyy"
            )}
          />
        </InfoCardContainer>
      </div>

      <DownloadBox>
        <div style={{ textAlign: "center" }}>
          <img alt="constancia" src="./icons/document.svg" width={60}></img>
        </div>

        <SmallButton
          variant="contained"
          endIcon={<img alt="document" src="./icons/document.svg" width={6} />}
          onClick={() => onClick(data.id, data.codigo)}
        >
          Descargar
        </SmallButton>
      </DownloadBox>
    </ContainerCard>
  );
};

export default WorkshopCard;
