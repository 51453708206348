import { api } from "./configs/axiosConfigs";

export const RewardStudentAPI = {
  getRewardStudentList: async () => {
    const response = await api
      .get("/rewardStudent/list")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
  getRewardStudent: async (id: number) => {
    const response = await api
      .get(`/rewardStudent/detail/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getRewardStudentSearch: async (term) => {
    const response = await api
      .get(`/rewardStudent/list?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  discountRewardStudent: async (data: any) => {
    const response = await api
      .post("/rewardStudent", { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editRewardStudent: async (id: number, data: any) => {
    const response = await api
      .patch(`/rewardStudent/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteRewardStudent: async (id: number) => {
    const response = await api
      .delete(`/rewardStudent/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },
};
