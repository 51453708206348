import { FC, useState, useEffect, useCallback } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddStudentCycleModal } from "./components/AddStudentModal";
import { WorkshopStudent } from "../../types/slices/studentType";
import { UploadExcelModal } from "./components/UploadExcelModal";
import { useStudentStore } from "../../hooks/useStudentStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useWorkshopCycleStore } from "../../hooks/useWorkshopCycleStore";
import { WorkshopCycle } from "../../types/slices/workshopCycleType";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "dni_student", label: "DNI" },
  {
    type: "text",
    field: "name_student",
    label: "NOMBRE",
    format: (row) => row.name_student + " " + row.surname_student,
  },
  { type: "checkbox", field: "status_document", label: "DIGITAL" },
  { type: "checkbox", field: "print_document", label: "FISICO" },
  {
    type: "text",
    field: "sent_print",
    label: "ENVIADO",
    format: (row) =>
      row.print_document === "1" ? (row.sent_print === "1" ? "Si" : "No") : "",
  },
  { type: "multi-select", field: "", label: "" },
];

const StudentWorkshopCycle: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<WorkshopStudent>(
    {} as WorkshopStudent
  );
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [openModalExcel, setOpenModalExcel] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [studentsByWorkshopCycleData, setStudentsByWorkshopCycleData] =
    useState<WorkshopStudent[]>([]);
  const [filterBy, setFilterBy] = useState<string>("all");
  const {
    status,
    statusStudents,
    studentByWorkshopCycle,
    selectedWorkshopCycle,
    openModal,
    getStudentByWorkshopCycle,
    getStudentByWorkshopCycleSearch,
    getTemplateStudentByWorkshopCycle,
    setOpenModal,
    changeIsEdit,
    setSelectedWorkshopCycle,
    deleteWorkshopCycleStudent,
    fetchStudentByWorkshopCycle,
    editStudentWorkshopCycleStore,
  } = useWorkshopCycleStore();

  const { getWorkshopPDFMultipleTemplate } = useStudentStore();

  useEffect(() => {
    if (selectedWorkshopCycle.id) {
      setLoading(true);
      getStudentByWorkshopCycle(selectedWorkshopCycle.id).then((data) => {
        setStudentsByWorkshopCycleData(data);
        setLoading(false);
      });
    } else {
      navigate(RoutesMap.WORKSHOP_CYCLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkshopCycle, setLoading]);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    let idsStudent = JSON.stringify([rowSelected?.id_student]);

    const response = await deleteWorkshopCycleStudent(
      selectedWorkshopCycle?.id || 0,
      idsStudent
    );
    if (response === true) {
      // setSelected({} as Module);
      setModalDeleteConfirm(false);
      getStudentByWorkshopCycle(selectedWorkshopCycle.id || 0).then((data) =>
        setStudentsByWorkshopCycleData(data)
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goBack = () => {
    setSelectedWorkshopCycle({} as WorkshopCycle);
    navigate(RoutesMap.WORKSHOP_CYCLE);
  };

  const onChange = (value, updatedRow, field) => {
    const rowIndex = studentsByWorkshopCycleData.findIndex(
      (row) => row.id === updatedRow.id
    );

    if (rowIndex !== -1) {
      const updatedStudentByCycle: any = [...studentsByWorkshopCycleData];

      updatedStudentByCycle[rowIndex] = {
        ...updatedStudentByCycle[rowIndex],
        [field]: value,
      };

      setStudentsByWorkshopCycleData(updatedStudentByCycle);
      fetchStudentByWorkshopCycle(updatedStudentByCycle);
    }
  };

  const onClear = () => {
    if (!isSearch) {
      getStudentByWorkshopCycle(selectedWorkshopCycle.id || 0).then((data) =>
        setStudentsByWorkshopCycleData(data)
      );
    }
  };

  const onSave = async () => {
    const rowFind = studentsByWorkshopCycleData.find(
      (item) => item.id === rowSelected.id
    );
    await editStudentWorkshopCycleStore(rowFind?.id || 0, {
      status_document: rowFind?.status_document,
      print_document: rowFind?.print_document,
    });
    onClear();
    setRowSelected({} as WorkshopStudent);
  };

  const onDownloadExcel = async () => {
    const response: any = await getTemplateStudentByWorkshopCycle(
      selectedWorkshopCycle.id || 0
    );
    if (response) {
      const btn_excel = document.createElement("a");
      btn_excel.href = `${process.env.REACT_APP_ROOT_URL}/${response}`;
      document.body.appendChild(btn_excel);
      btn_excel.click();
      document.body.removeChild(btn_excel);
    }
  };

  const onDownloadTemplate = useCallback(() => {
    window.open("/plantilla-constancia.pdf", "_blank");
  }, []);

  const onUploadExcel = async () => {
    setOpenModalExcel(true);
  };

  const onSearch = (value) => {
    if (value.length >= 3) {
      setIsSearch(true);
      getStudentByWorkshopCycleSearch(
        selectedWorkshopCycle?.id || 0,
        value
      ).then((data) => setStudentsByWorkshopCycleData(data));
    }
    if (value.length === 0) {
      setIsSearch(false);
      getStudentByWorkshopCycle(selectedWorkshopCycle?.id || 0).then((data) =>
        setStudentsByWorkshopCycleData(data)
      );
    }
  };

  const onPrintMultipleWorkshopDocuments = async (
    side: string,
    type: string
  ) => {
    try {
      if (!selectedRows.length) {
        return CustomSnackbar("error", "Elija al menos una constancia");
      }
      const response = await getWorkshopPDFMultipleTemplate(
        selectedWorkshopCycle?.id,
        selectedRows.map(({ id_workshop_document }) => id_workshop_document),
        type,
        side
      );

      const url = window.URL.createObjectURL(response);
      const win = window.open(url, "_blank");
      win?.focus();
      URL.revokeObjectURL(url);
      getStudentByWorkshopCycle(selectedWorkshopCycle.id || 0).then((data) =>
        setStudentsByWorkshopCycleData(data)
      );
    } catch (e) {
      console.warn("No se pudo descargar. Volver a intentar.");
    }
  };

  const onPrintCertificate = async (rowSelected) => {
    try {
      const response = await getWorkshopPDFMultipleTemplate(
        selectedWorkshopCycle?.id,
        [rowSelected?.id_workshop_document],
        "format"
      );
      const url = window.URL.createObjectURL(response);
      const win = window.open(url, "_blank");
      win?.focus();
      URL.revokeObjectURL(url);
      onClear();
    } catch (e) {
      console.warn("No se pudo descargar. Volver a intentar.");
    }
  };

  const onPrintCertificateChange = useCallback(
    (value, row) => {
      if (value) {
        setSelectedRows((_: any) => [..._, row]);
      } else {
        setSelectedRows((rows) => {
          const rowIndex = rows.findIndex(
            (_: any) => _.id_workshop_document === row.id_workshop_document
          );
          if (rowIndex >= 0) {
            rows.splice(rowIndex, 1);
          }
          return [...rows];
        });
      }
    },
    [setSelectedRows]
  );

  const filterBySentPrint = useCallback(
    (value) => {
      const filtered = studentByWorkshopCycle.filter(
        ({ sent_print, print_document }) =>
          value === "all" ||
          (value === "print" && print_document === "1") ||
          (sent_print === value && print_document === "1")
      );
      setFilterBy(value);
      setStudentsByWorkshopCycleData(filtered);
      setPage(0);
    },
    [
      studentByWorkshopCycle,
      setFilterBy,
      setStudentsByWorkshopCycleData,
      setPage,
    ]
  );

  const onCheckAllToPrint = useCallback(
    (value) => {
      if (value) {
        const selectedToPrint = studentsByWorkshopCycleData.filter(
          ({ print_document }) => print_document === "1"
        );
        setSelectedRows(selectedToPrint);
      } else {
        setSelectedRows([]);
      }
    },
    [studentsByWorkshopCycleData, setSelectedRows]
  );

  const getStudentByWorkshopCycleCallback = useCallback(
    (value) => {
      getStudentByWorkshopCycle(value).then((data) =>
        setStudentsByWorkshopCycleData(data)
      );
    },
    [getStudentByWorkshopCycle, setStudentsByWorkshopCycleData]
  );

  return (
    <>
      {statusStudents === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{ marginLeft: "-25px" }}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="view"
          onClick={() => {
            goBack();
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography>Volver</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          isWorkshopDocument
          isMultipleEnabled={!Boolean(selectedRows.length)}
          onPrintMultipleCertificate={onPrintMultipleWorkshopDocuments}
          title={
            selectedWorkshopCycle
              ? `Estudiantes de ${selectedWorkshopCycle?.name} - ${selectedWorkshopCycle.workshops?.name}`
              : "Estudiantes del Ciclo"
          }
          columns={columns}
          loading={loading}
          rows={studentsByWorkshopCycleData}
          onRowClick={() => {}}
          hasOptions
          onDelete={onDelete}
          onAddFn={onAdd}
          onSearch={onSearch}
          onDownloadExcel={onDownloadExcel}
          onUploadExcel={onUploadExcel}
          onChangeInput={onChange}
          filterBy={filterBy}
          filterBySentPrint={filterBySentPrint}
          onPrintCertificate={onPrintCertificate}
          onPrintCertificateChange={onPrintCertificateChange}
          onDownloadTemplate={onDownloadTemplate}
          onSwitch
          editable
          onCheckAllToPrint={onCheckAllToPrint}
          selectedRows={selectedRows}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          setSelectedRows={setSelectedRows}
          onClear={onClear}
          onSave={onSave}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusStudents}
        />
      )}
      {openModalExcel && (
        <UploadExcelModal
          openModal={openModalExcel}
          setOpenModal={setOpenModalExcel}
          selectedWorkshopCycle={selectedWorkshopCycle}
          getStudentByWorkshopCycle={getStudentByWorkshopCycleCallback}
        />
      )}
      {openModal && (
        <AddStudentCycleModal onAddStudent={setStudentsByWorkshopCycleData} />
      )}
    </>
  );
};

export default StudentWorkshopCycle;
