import { Box, Link } from "@mui/material";
import { FC } from "react";

export const Footer: FC = () => {
  return (
    <Box
      style={{
        borderTop: "1px dashed #DEDEDE",
        paddingTop: 50,
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <Link
        style={{ cursor: "pointer" }}
        href="https://forms.gle/8hNm8dZUCCZX6Tp38 "
        target="_blank"
      >
        Libro de Reclamaciones {">"}
      </Link>
      <br />
      <Link
        style={{ cursor: "pointer" }}
        href="https://drive.google.com/file/d/15dDon5Oohnqtmd0hpUmM9mScOD9r_nq0/view"
        target="_blank"
      >
        Reglamento del Estudiante {">"}
      </Link>
      <br />
      <Link
        style={{ cursor: "pointer" }}
        href="https://drive.google.com/file/d/17Uzx1Nh_U2i08PxE_iI0eHMBsVAkmN4M/view?usp=sharing"
        target="_blank"
      >
        Políticas de Privacidad y Protección de Datos {">"}
      </Link>
      <br />
      <br />
      <br />
      <br />
    </Box>
  );
};
