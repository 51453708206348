import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import {
  WorkshopCycle,
  WorkshopCycleType,
  Teacher,
} from "../../types/slices/workshopCycleType";
import { Student } from "../../types/slices/studentType";
import { Program } from "../../types/slices/programType";
import { Workshop } from "../../types/slices/workshopsType";

const initialState: WorkshopCycleType = {
  status: ApiStatus.FETCHED,
  statusStudents: ApiStatus.FETCHED,
  workshopCycles: [],
  errorMessage: undefined,
  editWorkshopCycle: {} as WorkshopCycle,
  selectedWorkshopCycle: {} as WorkshopCycle,
  selectedWorkshop: {} as Workshop,
  teachers: [],
  programs: [],
  selectedTeacher: {} as Teacher,
  selectedProgram: {} as Program,
  studentByWorkshopCycle: [],
  studentsNotInWorkshopCycle: [],
  selectedStudentsNotInWorkshopCycle: [],
  isEdit: false,
  openModal: false,
};

const workshopCycleSlice = createSlice({
  name: "workshopCycle",
  initialState,
  reducers: {
    onFetchWorkshopCycle(state, { payload }: { payload: WorkshopCycle[] }) {
      state.status = ApiStatus.FETCHED;
      state.workshopCycles = payload;
      state.errorMessage = undefined;
    },
    onFetchStudentByWorkshopCycle(state, { payload }: { payload: Student[] }) {
      state.status = ApiStatus.FETCHED;
      state.studentByWorkshopCycle = payload;
      state.errorMessage = undefined;
    },
    onFetchStudentNotInWorkshopCycle(
      state,
      { payload }: { payload: Student[] }
    ) {
      state.status = ApiStatus.FETCHED;
      state.studentsNotInWorkshopCycle = payload;
      state.errorMessage = undefined;
    },
    onFetchWorkshop(state, { payload }: { payload: Workshop }) {
      state.status = ApiStatus.FETCHED;
      state.selectedWorkshop = payload;
      state.errorMessage = undefined;
    },
    onFetchTeachers(state, { payload }: { payload: Teacher[] }) {
      state.status = ApiStatus.FETCHED;
      state.teachers = payload;
      state.errorMessage = undefined;
    },
    onFetchPrograms(state, { payload }: { payload: Program[] }) {
      state.status = ApiStatus.FETCHED;
      state.programs = payload;
      state.errorMessage = undefined;
    },
    changeStatus(state, { payload }: { payload: ApiStatus }) {
      state.status = payload;
    },
    onSetEditWorkshopCycle(state, { payload }: { payload: WorkshopCycle }) {
      state.editWorkshopCycle = payload;
    },
    onSetSelectedWorkshopCycle(state, { payload }: { payload: WorkshopCycle }) {
      state.selectedWorkshopCycle = payload;
    },
    onSetSelectedTeacher(state, { payload }: { payload: Teacher }) {
      state.selectedTeacher = payload;
    },
    onSetSelectedProgram(state, { payload }: { payload: Program }) {
      state.selectedProgram = payload;
    },
    onSetSelectedStudentsNotInWorkshopCycle(
      state,
      { payload }: { payload: Student[] }
    ) {
      state.selectedStudentsNotInWorkshopCycle = payload;
    },
    onSetOpenModal(state, { payload }: { payload: boolean }) {
      state.openModal = payload;
    },
    onChangeIsEdit(state, { payload }: { payload: boolean }) {
      state.isEdit = payload;
    },
  },
});

export const selectWorkshopCycleState = (state: RootState) =>
  state.workshopCycleSlice;
export default workshopCycleSlice.reducer;

export const {
  onFetchWorkshopCycle,
  changeStatus,
  onFetchStudentByWorkshopCycle,
  onFetchStudentNotInWorkshopCycle,
  onFetchWorkshop,
  onFetchTeachers,
  onFetchPrograms,
  onSetOpenModal,
  onSetEditWorkshopCycle,
  onSetSelectedWorkshopCycle,
  onChangeIsEdit,
  onSetSelectedTeacher,
  onSetSelectedProgram,
  onSetSelectedStudentsNotInWorkshopCycle,
} = workshopCycleSlice.actions;
