import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import {
  changeStatusPrograms,
  onChangeIsEdit,
  onFetchPrograms,
  onSetEditProgram,
  onSetOpenModal,
  onSetSelectedProgram,
  selectProgramState,
} from "../redux/slices/programSlice";
import { ProgramAPI } from "../apis/ProgramAPI";
import { Program } from "../types/slices/programType";

export const useProgramStore = () => {
  const dispatch = useDispatch();
  const {
    programs,
    statusPrograms,
    editProgram,
    selectedProgram,
    openModal,
    isEdit,
  } = useSelector(selectProgramState);

  const getPrograms = async () => {
    try {
      dispatch(changeStatusPrograms(ApiStatus.FETCHING));
      const response = await ProgramAPI.getPrograms();
      if (!response?.status) {
        dispatch(changeStatusPrograms(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchPrograms(detail));
      dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    }
  };

  const getProgramsSearch = async (term) => {
    try {
      dispatch(changeStatusPrograms(ApiStatus.FETCHING));
      const response = await ProgramAPI.getProgramsSearch(term);
      if (!response?.status) {
        dispatch(changeStatusPrograms(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchPrograms(detail));
      dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    }
  };

  const createProgramstore = async (data: any) => {
    try {
      dispatch(changeStatusPrograms(ApiStatus.FETCHING));
      const response = await ProgramAPI.createProgram(data);
      if (!response?.status) {
        dispatch(changeStatusPrograms(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusPrograms(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    }
  };

  const editProgramstore = async (id: number, data: any) => {
    try {
      dispatch(changeStatusPrograms(ApiStatus.FETCHING));
      const response = await ProgramAPI.editProgram(id, data);
      if (!response?.status) {
        dispatch(changeStatusPrograms(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusPrograms(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    }
  };

  const deleteProgram = async (id: number) => {
    try {
      dispatch(changeStatusPrograms(ApiStatus.FETCHING));
      const response = await ProgramAPI.deleteProgram(id);
      if (!response?.status) {
        dispatch(changeStatusPrograms(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatusPrograms(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusPrograms(ApiStatus.FETCHED));
    }
  };

  const setSelectedProgram = (program: Program) => {
    try {
      dispatch(onSetSelectedProgram(program));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditProgram = (program: Program) => {
    try {
      dispatch(onSetEditProgram(program));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    programs,
    openModal,
    isEdit,
    statusPrograms,
    editProgram,
    selectedProgram,
    //actions
    getPrograms,
    deleteProgram,
    setEditProgram,
    setOpenModal,
    setSelectedProgram,
    changeIsEdit,
    editProgramstore,
    createProgramstore,
    getProgramsSearch,
  };
};
