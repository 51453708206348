import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import {
  onFetchCycle,
  selectCycleState,
  changeStatus,
  onSetSelectedCycle,
  onSetEditcycle,
  onSetOpenModal,
  onChangeIsEdit,
  onFetchTeachers,
  onFetchPrograms,
  onSetSelectedTeacher,
  onFetchStudentByCycle,
  onFetchStudentNotInCycle,
  onSetSelectedStudentsNotInCycle,
  onSetSelectedProgram,
} from "../redux/slices/cycleSlice";
import { CycleAPI } from "../apis/CycleAPI";
import { Cycle, Teacher } from "../types/slices/cycleType";
import { Student } from "../types/slices/studentType";
import { Program } from "../types/slices/programType";

export const useCycleStore = () => {
  const dispatch = useDispatch();
  const {
    cycles,
    status,
    statusStudents,
    openModal,
    isEdit,
    editCycle,
    selectedCycle,
    selectedTeacher,
    teachers,
    selectedProgram,
    programs,
    studentByCycle,
    studentsNotInCycle,
    selectedStudentsNotInCycle,
  } = useSelector(selectCycleState);

  const getCycle = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getCycle();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getTemplateStudentByCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getTemplateStudentByCycle(idcycle);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return;
      }
      const { detail } = response.data;
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const uploadTemplateStudentByCycle = async (idcycle: number, data) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.uploadTemplateStudentByCycle(
        idcycle,
        data
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentByCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getStudentByCycle(idcycle);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentByCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentByCycleSearch = async (idcycle: number, term: string) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getStudentByCycleSearch(idcycle, term);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentByCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentNotInCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getStudentNotInCycle(idcycle);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentNotInCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getTeacher = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getTeacher();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchTeachers(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getProgram = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.getProgram();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchPrograms(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const createCycleStore = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.createCycle(data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const createRelationStudentCycle = async (idcycle: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.createRelationStudentCycle(idcycle, data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const editStudentCycleStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.editStudentCycle(id, data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const editCycleStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.editCycle(id, data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const deleteCycle = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.deleteCycle(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const deleteCycleStudent = async (id: number, idsStudent) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CycleAPI.deleteStudentCycle(id, idsStudent);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const setSelectedCycle = (cycle: Cycle) => {
    try {
      dispatch(onSetSelectedCycle(cycle));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudentByCycle = (data: Student[]) => {
    try {
      dispatch(onFetchStudentByCycle(data));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedStudentsNotInCycle = (students: Student[]) => {
    try {
      dispatch(onSetSelectedStudentsNotInCycle(students));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedTeacher = (teacher: Teacher) => {
    try {
      dispatch(onSetSelectedTeacher(teacher));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedProgram = (program: Program) => {
    try {
      dispatch(onSetSelectedProgram(program));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditCycle = (cycle: Cycle) => {
    try {
      dispatch(onSetEditcycle(cycle));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    //states
    cycles,
    status,
    statusStudents,
    openModal,
    isEdit,
    editCycle,
    teachers,
    programs,
    selectedTeacher,
    selectedProgram,
    selectedCycle,
    studentByCycle,
    studentsNotInCycle,
    selectedStudentsNotInCycle,
    //actions
    getCycle,
    getTeacher,
    getProgram,
    getStudentNotInCycle,
    getTemplateStudentByCycle,
    uploadTemplateStudentByCycle,
    createCycleStore,
    editCycleStore,
    deleteCycle,
    setOpenModal,
    changeIsEdit,
    setSelectedCycle,
    setSelectedStudentsNotInCycle,
    setEditCycle,
    setSelectedProgram,
    setSelectedTeacher,
    getStudentByCycle,
    getStudentByCycleSearch,
    deleteCycleStudent,
    createRelationStudentCycle,
    fetchStudentByCycle,
    editStudentCycleStore,
  };
};
