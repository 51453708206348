import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import {
  RewardStudent,
  RewardStudentList,
  rewardStudentType,
} from "../../types/slices/rewardStudentType";

const initialState: rewardStudentType = {
  statusRewardStudent: ApiStatus.FETCHED,
  rewardStudent: [],
  rewardStudentList: [],
  errorMessage: undefined,
  discountRewardStudent: {} as RewardStudent,
  selectedRewardStudent: {} as RewardStudent,
  selectedRewardStudentList: {} as RewardStudentList,
  isEdit: false,
  openModal: false,
};

const rewardStudentSlice = createSlice({
  name: "rewardStudent",
  initialState,
  reducers: {
    onFetchRewardStudentList(
      state,
      { payload }: { payload: RewardStudentList[] }
    ) {
      state.statusRewardStudent = ApiStatus.FETCHED;
      state.rewardStudentList = payload;
      state.errorMessage = undefined;
    },
    onFetchRewardStudent(state, { payload }: { payload: RewardStudent[] }) {
      state.statusRewardStudent = ApiStatus.FETCHED;
      state.rewardStudent = payload;
      state.errorMessage = undefined;
    },
    changeStatusRewardStudent(state, { payload }: { payload: ApiStatus }) {
      state.statusRewardStudent = payload;
    },
    onSetDiscountRewardStudent(state, { payload }: { payload: RewardStudent }) {
      state.discountRewardStudent = payload;
    },
    onSetSelectedRewardStudentList(
      state,
      { payload }: { payload: RewardStudentList }
    ) {
      state.selectedRewardStudentList = payload;
    },
    onSetSelectedRewardStudent(state, { payload }: { payload: RewardStudent }) {
      state.selectedRewardStudent = payload;
    },
    onSetOpenModal(state, { payload }: { payload: boolean }) {
      state.openModal = payload;
    },
    onChangeIsEdit(state, { payload }: { payload: boolean }) {
      state.isEdit = payload;
    },
  },
});

export const selectRewardStudentState = (state: RootState) =>
  state.rewardStudentSlice;
export default rewardStudentSlice.reducer;

export const {
  onFetchRewardStudentList,
  onFetchRewardStudent,
  changeStatusRewardStudent,
  onSetDiscountRewardStudent,
  onSetSelectedRewardStudent,
  onSetSelectedRewardStudentList,
  onSetOpenModal,
  onChangeIsEdit,
} = rewardStudentSlice.actions;
