import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ResultLayout } from "./components/ResultLayout";
import "./HomeView.css";

const themeAvenir = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 15,
  },
});

export const HomeView = () => {
  return (
    <ThemeProvider theme={themeAvenir}>
      <ResultLayout />
    </ThemeProvider>
  );
};
