import { useDispatch, useSelector } from "react-redux";
import { StudentAPI } from "../apis/StudentAPI";
import {
  selectStudentState,
  changeStatus,
  onFetchStudent,
  onSetEditStudent,
  onSetSelectedStudent,
  onSetOpenModal,
  onChangeIsEdit,
} from "../redux/slices/studentSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Student } from "../types/slices/studentType";
import { CertificateAPI } from "../apis/CertificateAPI";
import { WorkshopDocumentAPI } from "../apis/WorkshopDocumentAPI";
import { RewardStudentAPI } from "../apis/RewardStudentAPI";

export const useStudentStore = () => {
  const dispatch = useDispatch();
  const { students, status, editStudent, selectedStudent, openModal, isEdit } =
    useSelector(selectStudentState);

  const getStudents = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await StudentAPI.getStudents();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentsSearch = async (term) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await StudentAPI.getStudentsSearch(term);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificate = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.getCertificate();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return [];
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificateSearch = async (term) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.getCertificateSerch(term);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return [];
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificateByDNI = async (dni) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.getCertificateByDNI(dni);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return response;
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      if (detail) dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificatePDF = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.downloadPDF(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificatePDFTemplate = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.downloadPDFTemplate(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificatePDFMultipleTemplate = async (
    idcycle,
    ids: number[],
    type: string,
    side?: string
  ) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.downloadPDFMultipleTemplate(
        idcycle,
        ids,
        type,
        side
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getCertificatePDFByURL = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await CertificateAPI.downloadPDFByUrl(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopDocument = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.getWorkshopDocument();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return [];
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopSearch = async (term) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.getWorkshopDocumentSearch(
        term
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return [];
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopByDNI = async (dni) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.getWorkshopDocumentByDNI(dni);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return response;
      }
      const { detail } = response.data;
      // dispatch(onFetchStudent(detail));
      if (detail) dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopPDF = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.downloadPDF(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopPDFTemplate = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.downloadPDFTemplate(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopPDFMultipleTemplate = async (
    idcycle,
    ids: number[],
    type: string,
    side?: string
  ) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.downloadPDFMultipleTemplate(
        idcycle,
        ids,
        type,
        side
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getWorkshopPDFByURL = async (id) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopDocumentAPI.downloadPDFByUrl(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message);
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const createStudentStore = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await StudentAPI.createStudents(data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const editStudentStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await StudentAPI.editStudents(id, data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const deleteStudents = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await StudentAPI.deleteStudents(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const setSelectedStudent = (student: Student) => {
    try {
      dispatch(onSetSelectedStudent(student));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditStudent = (student: Student) => {
    try {
      dispatch(onSetEditStudent(student));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  const getRewardStudent = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.getRewardStudent(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }

      dispatch(changeStatus(ApiStatus.FETCHED));
      return response.data;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  return {
    //states
    students,
    status,
    editStudent,
    selectedStudent,
    openModal,
    isEdit,
    //actions
    getStudents,
    getStudentsSearch,
    createStudentStore,
    editStudentStore,
    deleteStudents,
    setSelectedStudent,
    setEditStudent,
    setOpenModal,
    changeIsEdit,
    getRewardStudent,
    getCertificate,
    getCertificateSearch,
    getCertificateByDNI,
    getCertificatePDF,
    getCertificatePDFByURL,
    getCertificatePDFTemplate,
    getCertificatePDFMultipleTemplate,

    getWorkshop: getWorkshopDocument,
    getWorkshopSearch,
    getWorkshopByDNI,
    getWorkshopPDF,
    getWorkshopPDFByURL,
    getWorkshopPDFTemplate,
    getWorkshopPDFMultipleTemplate,
  };
};
