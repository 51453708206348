import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useStudentStore } from "../../hooks/useStudentStore";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { Student } from "../../types/slices/studentType";
import { CreateEditStudentModal } from "./components/CreateEditStudentModal";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

const columns = [
  { type: "options", field: "options", label: "Opciones", align: "center" },
  { type: "text", field: "dni", label: "DNI", align: "center" },
  { type: "text", field: "name", label: "NOMBRES", align: "center" },
  { type: "text", field: "surname", label: "APELLIDOS", align: "center" },
];

const Students: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Student>({} as Student);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

  const {
    students,
    status: studentStatus,
    getStudents,
    getStudentsSearch,
    setSelectedStudent,
    deleteStudents,
    setOpenModal,
    setEditStudent,
    changeIsEdit,
    openModal,
  } = useStudentStore();

  useEffect(() => {
    setLoading(true);
    getStudents().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditStudent(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteStudents(rowSelected?.id || 0);
    if (response === true) {
      setSelectedStudent({} as Student);
      setModalDeleteConfirm(false);
      getStudents();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = (value) => {
    if (value.length >= 3) {
      getStudentsSearch(value);
    }
    if (value.length === 0) {
      getStudents();
    }
  };

  return (
    <>
      {studentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Alumnos"}
          columns={columns}
          loading={loading}
          rows={students || []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onSearch={onSearch}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={studentStatus}
        />
      )}
      {openModal && <CreateEditStudentModal />}
    </>
  );
};

export default Students;
