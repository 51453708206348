import { FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { useStudentStore } from "../../../hooks/useStudentStore";
import { Student } from "../../../types/slices/studentType";
import { Formik } from "formik";
import { useEffect, useState } from "react";

export const CreateEditStudentModal: React.FC<any> = (): JSX.Element | any => {
  const {
    editStudent,
    openModal,
    setOpenModal,
    isEdit,
    createStudentStore,
    editStudentStore,
    getStudents,
  } = useStudentStore();

  const [data, setData] = useState<Student>({
    dni: "",
    name: "",
    surname: "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    let regdni = /^[0-9]{8,12}$/;
    if (!values.dni) errors.dni = "DNI es requerido";
    if (values.dni && !regdni.test(values.dni)) errors.dni = "dni no válido";
    if (!values.name) errors.name = "Nombre es requerido";
    if (values.name && values.name.length > 100)
      errors.name = "Este campo tiene un limite de 100 caracteres";
    if (!values.surname) errors.surname = "Apellido es requerido";
    if (values.surname && values.surname.length > 100)
      errors.surname = "Este campo tiene un limite de 100 caracteres";

    return errors;
  };

  const onSubmit = async (values) => {
    const method = isEdit
      ? editStudentStore(editStudent?.id || 0, values)
      : createStudentStore(values);
    const response = await method;
    if (response === true) {
      getStudents();
      setOpenModal(false);
    }
  };

  const clear = () => {
    setData({
      dni: "",
      name: "",
      surname: "",
    });
  };

  useEffect(() => {
    if (isEdit && openModal) {
      setData(editStudent);
    } else {
      setData({
        dni: "",
        name: "",
        surname: "",
      });
    }
  }, [isEdit, editStudent, openModal]);

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => {
          clear();
          setOpenModal(false);
        }}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Alumno" : "Agregar Alumno"}
          className="positionElements"
          onCancel={() => {
            clear();
            setOpenModal(false);
          }}
          // clearState={clearEditStudent}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>DNI:</FormLabel>
                          <TextField
                            id="dni"
                            type="text"
                            name="dni"
                            fullWidth
                            size="small"
                            value={values.dni}
                            onChange={handleChange}
                            error={errors.dni && touched.dni ? true : false}
                            helperText={
                              errors.dni && touched.dni ? errors.dni : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Nombres:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Apellidos:</FormLabel>
                          <TextField
                            id="surname"
                            type="text"
                            name="surname"
                            fullWidth
                            size="small"
                            value={values.surname}
                            onChange={handleChange}
                            error={
                              errors.surname && touched.surname ? true : false
                            }
                            helperText={
                              errors.surname && touched.surname
                                ? errors.surname
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                  // loadingConfirmText={status2}
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
