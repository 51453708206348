import {
  Grid,
  Typography,
  Card,
  Avatar,
  TextField,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import {
  showBackdrop,
  showSnackBar,
} from "../../toolbox/helpers/custom-action";
import { Formik } from "formik";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useDispatch } from "../../redux/store";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Values = {
  name: string;
  surname: string;
  birthdate: string;
  address: string;
  membership: string;
  email: string;
  phone: string;
};

export const MyProfileView = () => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState<boolean>(false);
  const [data] = useState<Values>({
    name: "",
    surname: "",
    birthdate: "",
    address: "",
    membership: "",
    email: "",
    phone: "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.name) errors.name = "nombre requerido";
    if (!values.surname) errors.surname = "apellido requerido";
    if (!values.birthdate) errors.birthdate = "fecha de nacimiento requerido";
    if (!values.address) errors.address = "direccion requerido";
    if (!values.membership) errors.membership = "fecha de afiliacion requerido";
    if (!values.email) errors.email = "correo requerido";
    if (!values.phone) errors.phone = "telefono requerido";
    return errors;
  };

  const onSubmit = async (values) => {
    // dispatch(createUser(formData)).then(res => {
    //     dispatch(showBackdrop(false));
    // }).catch(err => {
    //     dispatch(showSnackBar('error', 'Ocurrió algun error, intentelo mas tarde'));
    //     dispatch(showBackdrop(false));
    // });

    //prueba
    dispatch(showBackdrop(true));

    dispatch(
      showSnackBar("success", "Los datos fueron actualizados con éxito.")
    );

    setTimeout(() => {
      dispatch(showBackdrop(false));
    }, 2000);
  };
  return (
    <>
      <Grid container sx={{ padding: "30px 30px 30px 30px" }}>
        <Grid
          item
          container
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Card
            sx={{
              width: "700px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              border: "solid 1px #808080",
              padding: "10px",
            }}
          >
            <Grid sx={{ mr: 3 }}>
              <Avatar alt="Remy Sharp" src="" sx={{ width: 56, height: 56 }} />
            </Grid>
            <Grid>
              <div>
                <Typography
                  color="#212D39"
                  sx={{ fontSize: 24, fontWeight: 600 }}
                >
                  {"Roinaldo Nazario De Lima"}
                </Typography>
              </div>
              <div>
                <Typography
                  color="#212D39"
                  sx={{ fontSize: 12, fontWeight: 400 }}
                >
                  {"Modifica los datos relacionados con tu perfil"}
                </Typography>
              </div>
            </Grid>
          </Card>
        </Grid>
        <Grid
          item
          container
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Accordion sx={{ width: "700px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Mis Datos</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Formik
                initialValues={data}
                enableReinitialize
                validate={(values) => validateForm(values)}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container sx={{ padding: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Nombre"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="name"
                              variant="standard"
                              type="text"
                              name="name"
                              multiline
                              fullWidth
                              value={values.name}
                              onChange={handleChange}
                              placeholder={"name"}
                              error={errors.name && touched.name ? true : false}
                              helperText={
                                errors.name && touched.name ? errors.name : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Apellido"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="surname"
                              variant="standard"
                              type="text"
                              name="surname"
                              multiline
                              fullWidth
                              value={values.surname}
                              onChange={handleChange}
                              placeholder={"surname"}
                              error={
                                errors.surname && touched.surname ? true : false
                              }
                              helperText={
                                errors.surname && touched.surname
                                  ? errors.surname
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Fecha de nacimiento"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="birthdate"
                              variant="standard"
                              type="date"
                              name="birthdate"
                              fullWidth
                              value={values.birthdate}
                              onChange={handleChange}
                              placeholder={"birthdate"}
                              error={
                                errors.birthdate && touched.birthdate
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.birthdate && touched.birthdate
                                  ? errors.birthdate
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Dirección"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="address"
                              variant="standard"
                              type="text"
                              name="address"
                              multiline
                              fullWidth
                              value={values.address}
                              onChange={handleChange}
                              placeholder={"address"}
                              error={
                                errors.address && touched.address ? true : false
                              }
                              helperText={
                                errors.address && touched.address
                                  ? errors.address
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Fecha de afiliación"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="membership"
                              variant="standard"
                              type="date"
                              name="membership"
                              fullWidth
                              value={values.membership}
                              onChange={handleChange}
                              placeholder={"membership"}
                              error={
                                errors.membership && touched.membership
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.membership && touched.membership
                                  ? errors.membership
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Correo"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="email"
                              variant="standard"
                              type="email"
                              name="email"
                              multiline
                              fullWidth
                              value={values.email}
                              onChange={handleChange}
                              placeholder={"email"}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          justifyContent="center"
                          marginBottom="25px"
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              className="custom-input"
                            >
                              <b>{"Teléfono"}:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              id="phone"
                              variant="standard"
                              type="number"
                              name="phone"
                              multiline
                              fullWidth
                              value={values.phone}
                              onChange={handleChange}
                              placeholder={"phone"}
                              error={
                                errors.phone && touched.phone ? true : false
                              }
                              helperText={
                                errors.phone && touched.phone
                                  ? errors.phone
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider />
                      {edit && (
                        <CustomFormFooter
                          buttonType="submit"
                          confirmText={"Guiardar Cambios"}
                          onConfirm={handleSubmit}
                          cancelText={"Descartar cambios"}
                          onCancel={() => {
                            setEdit(false);
                          }}
                        />
                      )}
                      {!edit && (
                        <CustomFormFooter
                          buttonType="submit"
                          cancelText={"Editar mis Datos"}
                          onCancel={() => {
                            setEdit(true);
                          }}
                        />
                      )}
                    </form>
                  );
                }}
              </Formik>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          item
          container
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Accordion sx={{ width: "700px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Privacidad</Typography>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
