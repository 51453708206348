import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import {
  onSetDiscountRewardStudent,
  onSetSelectedRewardStudent,
  selectRewardStudentState,
  onSetOpenModal,
  onChangeIsEdit,
  changeStatusRewardStudent,
  onFetchRewardStudent,
  onFetchRewardStudentList,
  onSetSelectedRewardStudentList,
} from "../redux/slices/rewardStudentSlice";
import {
  RewardStudent,
  RewardStudentList,
} from "../types/slices/rewardStudentType";
import { RewardStudentAPI } from "../apis/RewardStudentAPI";

export const useRewardStudentStore = () => {
  const dispatch = useDispatch();
  const {
    rewardStudent,
    rewardStudentList,
    statusRewardStudent,
    discountRewardStudent,
    selectedRewardStudent,
    selectedRewardStudentList,
    openModal,
    isEdit,
  } = useSelector(selectRewardStudentState);

  const getRewardStudentList = async () => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.getRewardStudentList();
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewardStudentList(detail));
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };
  const getRewardStudent = async (id: number) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.getRewardStudent(id);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewardStudent(detail));
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  const setRewardStudent = (detail: RewardStudent[]) => {
    dispatch(onFetchRewardStudent(detail));
  };

  const getRewardStudentListSearch = async (term) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.getRewardStudentSearch(term);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewardStudentList(detail));
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  const getRewardStudentSearch = async (term) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.getRewardStudentSearch(term);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewardStudent(detail));
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  const discountRewardStudentStore = async (data: any) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.discountRewardStudent(data);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  const editRewardStudent = async (id: number, data: any) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.editRewardStudent(id, data);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  const setSelectedRewardStudentList = (reward: RewardStudentList) => {
    try {
      dispatch(onSetSelectedRewardStudentList(reward));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedRewardStudent = (reward: RewardStudent) => {
    try {
      dispatch(onSetSelectedRewardStudent(reward));
    } catch (error) {
      console.log(error);
    }
  };

  const setDiscountRewardStudent = (reward: RewardStudent) => {
    try {
      dispatch(onSetDiscountRewardStudent(reward));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRewardStudent = async (id: number) => {
    try {
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHING));
      const response = await RewardStudentAPI.deleteRewardStudent(id);
      if (!response?.status) {
        dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewardStudent(ApiStatus.FETCHED));
    }
  };

  return {
    rewardStudent,
    rewardStudentList,
    openModal,
    isEdit,
    statusRewardStudent,
    discountRewardStudent,
    editRewardStudent,
    selectedRewardStudentList,
    selectedRewardStudent,
    //actions
    getRewardStudentListSearch,
    getRewardStudentList,
    getRewardStudent,
    deleteRewardStudent,
    setDiscountRewardStudent,
    discountRewardStudentStore,
    setOpenModal,
    setSelectedRewardStudent,
    setSelectedRewardStudentList,
    changeIsEdit,
    getRewardStudentSearch,
    setRewardStudent,
  };
};
