import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

//Custom error handler
const errorHandler = (error) => {
  const statusCode = error.response?.status;
  if (statusCode && statusCode === 401) {
    console.error(error);
    localStorage.clear();
    window.location.href = "/login";
  }
  return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
