import { useDispatch, useSelector } from "react-redux";

import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import {
  selectWorkshopState,
  changeStatusWorkshops,
  onFetchWorkshop,
  onSetSelectedWorkshop,
  onSetEditWorkshop,
  onSetOpenModal,
  onChangeIsEdit,
} from "../redux/slices/workshopSlice";
import { Workshop } from "../types/slices/workshopsType";
import { WorkshopAPI } from "../apis/WorkshopAPI";

export const useWorkshopStore = () => {
  const dispatch = useDispatch();
  const {
    workshops,
    statusWorkshops,
    editWorkshop,
    selectedWorkshop,
    openModal,
    isEdit,
  } = useSelector(selectWorkshopState);

  const getWorkshops = async () => {
    try {
      dispatch(changeStatusWorkshops(ApiStatus.FETCHING));
      const response = await WorkshopAPI.getWorkshops();
      if (!response?.status) {
        dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchWorkshop(detail));
      dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    }
  };

  const getWorkshopsSearch = async (term) => {
    try {
      dispatch(changeStatusWorkshops(ApiStatus.FETCHING));
      const response = await WorkshopAPI.getWorkshopsSearch(term);
      if (!response?.status) {
        dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchWorkshop(detail));
      dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    }
  };

  const createWorkshopStore = async (data: any) => {
    try {
      dispatch(changeStatusWorkshops(ApiStatus.FETCHING));

      const response = await WorkshopAPI.createWorkshop(data);
      if (!response?.status) {
        dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    }
  };

  const editWorkshopStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatusWorkshops(ApiStatus.FETCHING));
      const response = await WorkshopAPI.editWorkshop(id, data);
      if (!response?.status) {
        dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    }
  };

  const deleteWorkshop = async (id: number) => {
    try {
      dispatch(changeStatusWorkshops(ApiStatus.FETCHING));
      const response = await WorkshopAPI.deleteWorkshop(id);
      if (!response?.status) {
        dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusWorkshops(ApiStatus.FETCHED));
    }
  };

  const setSelectedWorkshop = (course: Workshop) => {
    try {
      dispatch(onSetSelectedWorkshop(course));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditWorkshop = (course: Workshop) => {
    try {
      dispatch(onSetEditWorkshop(course));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    //states
    workshops,
    openModal,
    isEdit,
    statusWorkshops,
    editWorkshop,
    selectedWorkshop,
    //actions
    getWorkshops,
    getWorkshopsSearch,
    createWorkshopStore,
    editWorkshopStore,
    deleteWorkshop,
    setEditWorkshop,
    setOpenModal,
    setSelectedWorkshop,
    changeIsEdit,
  };
};
