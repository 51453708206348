import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import {
  changeStatusRewards,
  onChangeIsEdit,
  onFetchRewards,
  onSetEditReward,
  onSetOpenModal,
  onSetSelectedReward,
  selectRewardState,
} from "../redux/slices/rewardSlice";
import { RewardAPI } from "../apis/RewardAPI";
import { Reward } from "../types/slices/rewardType";

export const useRewardStore = () => {
  const dispatch = useDispatch();
  const {
    rewards,
    statusRewards,
    editReward,
    selectedReward,
    openModal,
    isEdit,
  } = useSelector(selectRewardState);

  const getRewards = async () => {
    try {
      dispatch(changeStatusRewards(ApiStatus.FETCHING));
      const response = await RewardAPI.getRewards();
      if (!response?.status) {
        dispatch(changeStatusRewards(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewards(detail));
      dispatch(changeStatusRewards(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewards(ApiStatus.FETCHED));
    }
  };

  const setRewards = (detail: Reward[]) => {
    dispatch(onFetchRewards(detail));
  };

  const getRewardsSearch = async (term) => {
    try {
      dispatch(changeStatusRewards(ApiStatus.FETCHING));
      const response = await RewardAPI.getRewardsSearch(term);
      if (!response?.status) {
        dispatch(changeStatusRewards(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchRewards(detail));
      dispatch(changeStatusRewards(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewards(ApiStatus.FETCHED));
    }
  };

  const createRewardstore = async (data: any) => {
    try {
      dispatch(changeStatusRewards(ApiStatus.FETCHING));
      const response = await RewardAPI.createReward(data);
      if (!response?.status) {
        dispatch(changeStatusRewards(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusRewards(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewards(ApiStatus.FETCHED));
    }
  };

  const editRewardstore = async (id: number, data: any) => {
    try {
      dispatch(changeStatusRewards(ApiStatus.FETCHING));
      const response = await RewardAPI.editReward(id, data);
      if (!response?.status) {
        dispatch(changeStatusRewards(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatusRewards(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewards(ApiStatus.FETCHED));
    }
  };

  const deleteReward = async (id: number) => {
    try {
      dispatch(changeStatusRewards(ApiStatus.FETCHING));
      const response = await RewardAPI.deleteReward(id);
      if (!response?.status) {
        dispatch(changeStatusRewards(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatusRewards(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatusRewards(ApiStatus.FETCHED));
    }
  };

  const setSelectedReward = (reward: Reward) => {
    try {
      dispatch(onSetSelectedReward(reward));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditReward = (reward: Reward) => {
    try {
      dispatch(onSetEditReward(reward));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    rewards,
    openModal,
    isEdit,
    statusRewards,
    editReward,
    selectedReward,
    //actions
    getRewards,
    deleteReward,
    setEditReward,
    setOpenModal,
    setSelectedReward,
    changeIsEdit,
    editRewardstore,
    createRewardstore,
    getRewardsSearch,
    setRewards,
  };
};
