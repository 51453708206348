import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useStudentStore } from "../../hooks/useStudentStore";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { Student } from "../../types/slices/studentType";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useCycleStore } from "../../hooks/useCycleStore";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "codigo", label: "CÓDIGO" },
  { type: "text", field: "cycle", label: "CICLO" },
  { type: "text", field: "name_complet_student", label: "ALUMNO" },
  { type: "detail", field: "", label: "" },
];

const CertificatesView: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Student>({} as Student);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<any>([]);

  const {
    status: studentStatus,
    getCertificate,
    getCertificateSearch,
    getCertificatePDFTemplate,
  } = useStudentStore();

  const { editStudentCycleStore } = useCycleStore();
  useEffect(() => {
    getCertificateApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertificateApi = async () => {
    setLoading(true);
    const response = await getCertificate();
    if (response) {
      setCertificate(response);
    }
    setLoading(false);
  };

  const getCertificateApiASearch = async (term) => {
    setLoading(true);
    const response = await getCertificateSearch(term);
    if (response) {
      setCertificate(response);
    }
    setLoading(false);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await editStudentCycleStore(
      rowSelected?.id_cycle_student || 0,
      {
        nota: rowSelected?.nota,
        print_certificate: "0",
        status_certificate: "2",
      }
    );
    if (response) {
      setModalDeleteConfirm(false);
      getCertificateApi();
      setRowSelected({} as Student);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onPrintCertificate = async (rowSelected) => {
    try {
      const response = await getCertificatePDFTemplate(rowSelected?.id);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(response);
      link.download = "certificado.pdf";
      document.body.append(link);
      link.click();
      link.remove();
    } catch (e) {
      console.warn("No se pudo descargar. Volver a intentar.");
    }
  };

  const onSearch = (value) => {
    if (value.length >= 3) {
      getCertificateApiASearch(value);
    }
    if (value.length === 0) {
      getCertificateApi();
    }
  };

  return (
    <>
      {studentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Certificados"}
          columns={columns}
          loading={loading}
          rows={certificate || []}
          onRowClick={() => {}}
          hasOptions
          onDelete={onDelete}
          onPrintCertificate={onPrintCertificate}
          onSearch={onSearch}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={studentStatus}
        />
      )}
    </>
  );
};

export default CertificatesView;
