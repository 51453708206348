import { FC, useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { Student } from "../../types/slices/studentType";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CreateEditWorkshopCycleModal } from "./components/CreateEditWorkshopCycleModal";
import { RoutesMap } from "../../types";
import { useWorkshopCycleStore } from "../../hooks/useWorkshopCycleStore";
import { WorkshopCycle } from "../../types/slices/workshopCycleType";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  {
    type: "text",
    field: "program",
    label: "PROGRAMA",
    format: (row) => row.programs?.name ?? "",
  },
  { type: "text", field: "name", label: "NOMBRE" },
  {
    type: "text",
    field: "teacher",
    label: "PROFESOR",
    format: (row) => row.teachers.name,
  },
  {
    type: "text",
    field: "workshop",
    label: "TALLER",
    format: (row) => row.workshops.name,
  },
  { type: "text", field: "date_start", label: "FECHA" },
  { type: "detail", field: "", label: "" },
];

const WorkshopCycleView: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Student>({} as Student);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [filteredCycles, setFilteredCycles] = useState<WorkshopCycle[]>([]);

  const {
    workshopCycles,
    status: cycleStatus,
    openModal,
    getWorkshopCycle,
    setSelectedWorkshopCycle,
    deleteWorkshopCycle,
    setOpenModal,
    setEditCycle,
    changeIsEdit,
  } = useWorkshopCycleStore();

  useEffect(() => {
    getWorkshopCycle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDetails = (rowSelected) => {
    setSelectedWorkshopCycle(rowSelected);
    navigate(RoutesMap.ALUMNOS_OF_WORKSHOP_CYCLE);
  };

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditCycle(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteWorkshopCycle(rowSelected?.id || 0);
    if (response === true) {
      setSelectedWorkshopCycle({} as WorkshopCycle);
      setModalDeleteConfirm(false);
      getWorkshopCycle();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = useCallback(
    (program) => {
      setFilteredCycles(() => {
        if (!program) return workshopCycles;

        return workshopCycles.filter(
          ({ idprogram }) => idprogram === program.id
        );
      });

      setLoading(false);
    },
    [workshopCycles, setFilteredCycles, setLoading]
  );

  useEffect(() => {
    if (!filteredCycles.length || workshopCycles.length) {
      setFilteredCycles(workshopCycles);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopCycles]);

  return (
    <>
      {cycleStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Ciclos de Talleres"}
          columns={columns}
          loading={loading}
          rows={filteredCycles || []}
          onRowClick={() => {}}
          hasOptions
          onSearchByOption={onSearch}
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onDetails={onDetails}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={cycleStatus}
        />
      )}
      {openModal && <CreateEditWorkshopCycleModal />}
    </>
  );
};

export default WorkshopCycleView;
