import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useProgramStore } from "../../hooks/useProgramStore";
import { CreateEditProgramModal } from "./components/CreateEditProgramModal";
import { Program } from "../../types/slices/programType";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "name", label: "NOMBRE" },
  {
    type: "text",
    field: "status",
    label: "STATUS",
    format: (row) => (row.status === "1" ? "Activo" : "Inactivo"),
  },
];

const Programs: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Program>({} as Program);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

  const {
    programs,
    statusPrograms,
    openModal,
    getPrograms,
    getProgramsSearch,
    setSelectedProgram,
    deleteProgram,
    setOpenModal,
    setEditProgram,
    changeIsEdit,
  } = useProgramStore();

  useEffect(() => {
    setLoading(true);
    getPrograms().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditProgram(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteProgram(rowSelected?.id || 0);
    if (response === true) {
      setSelectedProgram({} as Program);
      setModalDeleteConfirm(false);
      getPrograms();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = (value) => {
    if (value.length >= 1) {
      getProgramsSearch(value);
    }
    if (value.length === 0) {
      getPrograms();
    }
  };

  return (
    <>
      {statusPrograms === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Programas"}
          columns={columns}
          loading={loading}
          rows={programs || []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onSearch={onSearch}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusPrograms}
        />
      )}
      {openModal && <CreateEditProgramModal />}
    </>
  );
};

export default Programs;
