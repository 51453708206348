import { Box, styled, BoxProps } from "@mui/material";

const SocialBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: "fixed",
  display: "flex",
  flexDirection: "row",
  gap: 15,
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
  zIndex: 1,
  [theme.breakpoints.up("sm")]: {
    bottom: 20,
  },
  [theme.breakpoints.down("sm")]: {
    bottom: 0,
    backgroundColor: "#002a6d",
    padding: 10,
    paddingBottom: 2,
    width: "100%",
  },
}));

export const SocialSection = () => (
  <SocialBox>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.facebook.com/CFEFOCUS"
    >
      <img width="40" alt="facebook" src="./icons/facebook-icon.png" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.tiktok.com/@cfe.focus?_t=ZM-8uobxBsAbhN&_r=1"
    >
      <img width="40" alt="facebook" src="./icons/tiktok-icon.svg" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://linkedin.com/company/cfefocus"
    >
      <img width="40" alt="linkedin" src="./icons/linkedin-icon.png" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.instagram.com/cfefocus/"
    >
      <img width="40" alt="instagram" src="./icons/instagram-icon.png" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://whatsapp.com/channel/0029VaDySzwFHWq0sH7IvR12"
    >
      <img width="40" alt="whatsapp" src="./icons/whatsapp-icon.png" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.youtube.com/@CFEFOCUS"
    >
      <img width="40" alt="youtube" src="./icons/youtube-icon.png" />
    </a>
  </SocialBox>
);
