import { RoutesMap } from "../types";
import { Outlet } from "react-router-dom";

interface IRestrictedRouteProps {
  pathRequired: RoutesMap;
}

export const RestrictedRoute = (props: IRestrictedRouteProps) => {
  // const { pathRequired } = props
  //Hooks
  // const { user, status } = useAuthStore();
  // const location = useLocation();

  // const hasAccess = user?.views?.some(view => pathRequired.includes(view.url_event));

  return (
    // hasAccess ? (
    <Outlet />
    // ) : (
    //     status === AuthStatus.AUTHENTICATED
    //     ? <Navigate to="/404" state={{ from: location }} replace />
    //     : <Navigate to={RoutesMap.LOGIN} state={{ from: location }} replace />
    // )
  );
};
