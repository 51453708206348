import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useWorkshopStore } from "../../hooks/useWorkshopStore";
import { CreateEditWorkshopModal } from "./components/CreateEditWorkshopModal";
import { Workshop } from "../../types/slices/workshopsType";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "name", label: "NOMBRE" },
  { type: "text", field: "code", label: "CÓDIGO" },
  { type: "text", field: "n_academic_hours", label: "N° HRS. ACADEMICAS" },
];

const Workshops: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Workshop>({} as Workshop);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

  const {
    workshops,
    statusWorkshops,
    openModal,
    getWorkshops,
    getWorkshopsSearch,
    setSelectedWorkshop,
    deleteWorkshop,
    setOpenModal,
    setEditWorkshop,
    changeIsEdit,
  } = useWorkshopStore();

  useEffect(() => {
    setLoading(true);
    getWorkshops().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditWorkshop(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDetails = (rowSelected) => {
    setSelectedWorkshop(rowSelected);
    navigate(RoutesMap.MODULES_OF_COURSES);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteWorkshop(rowSelected?.id || 0);
    if (response === true) {
      setSelectedWorkshop({} as Workshop);
      setModalDeleteConfirm(false);
      getWorkshops();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = (value) => {
    if (value.length >= 1) {
      getWorkshopsSearch(value);
    }
    if (value.length === 0) {
      getWorkshops();
    }
  };

  return (
    <>
      {statusWorkshops === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Talleres"}
          columns={columns}
          loading={loading}
          rows={workshops || []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onSearch={onSearch}
          onDetails={onDetails}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusWorkshops}
        />
      )}
      {openModal && <CreateEditWorkshopModal />}
    </>
  );
};

export default Workshops;
