import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

import { Cycle, Teacher } from "../types/slices/cycleType";
import { Student } from "../types/slices/studentType";
import { Program } from "../types/slices/programType";
import {
  changeStatus,
  onChangeIsEdit,
  onFetchPrograms,
  onFetchStudentByWorkshopCycle,
  onFetchStudentNotInWorkshopCycle,
  onFetchTeachers,
  onFetchWorkshopCycle,
  onSetEditWorkshopCycle,
  onSetOpenModal,
  onSetSelectedProgram,
  onSetSelectedStudentsNotInWorkshopCycle,
  onSetSelectedTeacher,
  onSetSelectedWorkshopCycle,
  selectWorkshopCycleState,
} from "../redux/slices/workshopCycleSlice";
import { WorkshopCycleAPI } from "../apis/WorkshopCycleAPI";

export const useWorkshopCycleStore = () => {
  const dispatch = useDispatch();
  const {
    workshopCycles,
    status,
    statusStudents,
    openModal,
    isEdit,
    editWorkshopCycle,
    selectedWorkshopCycle,
    selectedTeacher,
    teachers,
    selectedProgram,
    programs,
    studentByWorkshopCycle,
    studentsNotInWorkshopCycle,
    selectedStudentsNotInWorkshopCycle,
  } = useSelector(selectWorkshopCycleState);

  const getWorkshopCycle = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getWorkshopCycle();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchWorkshopCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getTemplateStudentByWorkshopCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getTemplateStudentByWorkshopCycle(
        idcycle
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return;
      }
      const { detail } = response.data;
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const uploadTemplateStudentByWorkshopCycle = async (
    idcycle: number,
    data
  ) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response =
        await WorkshopCycleAPI.uploadTemplateStudentByWorkshopCycle(
          idcycle,
          data
        );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentByWorkshopCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getStudentByWorkshopCycle(
        idcycle
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentByWorkshopCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentByWorkshopCycleSearch = async (
    idcycle: number,
    term: string
  ) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getStudentByWorkshopCycleSearch(
        idcycle,
        term
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentByWorkshopCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
      return detail;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getStudentNotInWorkshopCycle = async (idcycle: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getStudentNotInWorkshopCycle(
        idcycle
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchStudentNotInWorkshopCycle(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getTeacher = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getTeacher();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchTeachers(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const getProgram = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.getProgram();
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      const { detail } = response.data;
      dispatch(onFetchPrograms(detail));
      dispatch(changeStatus(ApiStatus.FETCHED));
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const createWorkshopCycleStore = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.createWorkshopCycle(data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const createRelationStudentWorkshopCycle = async (
    idcycle: number,
    data: any
  ) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response =
        await WorkshopCycleAPI.createRelationStudentWorkshopCycle(
          idcycle,
          data
        );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const editStudentWorkshopCycleStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.editStudentWorkshopCycle(
        id,
        data
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const editWorkshopCycleStore = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.editWorkshopCycle(id, data);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        return CustomSnackbar("error", response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const deleteWorkshopCycle = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.deleteWorkshopCycle(id);
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const deleteWorkshopCycleStudent = async (id: number, idsStudent) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING));
      const response = await WorkshopCycleAPI.deleteStudentWorkshopCycle(
        id,
        idsStudent
      );
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED));
        CustomSnackbar("error", response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(changeStatus(ApiStatus.FETCHED));
    }
  };

  const setSelectedWorkshopCycle = (cycle: Cycle) => {
    try {
      dispatch(onSetSelectedWorkshopCycle(cycle));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudentByWorkshopCycle = (data: Student[]) => {
    try {
      dispatch(onFetchStudentByWorkshopCycle(data));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedStudentsNotInWorkshopCycle = (students: Student[]) => {
    try {
      dispatch(onSetSelectedStudentsNotInWorkshopCycle(students));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedTeacher = (teacher: Teacher) => {
    try {
      dispatch(onSetSelectedTeacher(teacher));
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedProgram = (program: Program) => {
    try {
      dispatch(onSetSelectedProgram(program));
    } catch (error) {
      console.log(error);
    }
  };

  const setEditCycle = (cycle: Cycle) => {
    try {
      dispatch(onSetEditWorkshopCycle(cycle));
    } catch (error) {
      console.log(error);
    }
  };

  const setOpenModal = (status: boolean) => {
    try {
      dispatch(onSetOpenModal(status));
    } catch (error) {
      console.log(error);
    }
  };

  const changeIsEdit = (status: boolean) => {
    try {
      dispatch(onChangeIsEdit(status));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    //states
    workshopCycles,
    status,
    statusStudents,
    openModal,
    isEdit,
    editWorkshopCycle,
    teachers,
    programs,
    selectedTeacher,
    selectedProgram,
    selectedWorkshopCycle,
    studentByWorkshopCycle,
    studentsNotInWorkshopCycle,
    selectedStudentsNotInWorkshopCycle,
    //actions
    getWorkshopCycle,
    getTeacher,
    getProgram,
    getStudentNotInWorkshopCycle,
    getTemplateStudentByWorkshopCycle,
    uploadTemplateStudentByWorkshopCycle,
    createWorkshopCycleStore,
    editWorkshopCycleStore,
    deleteWorkshopCycle,
    setOpenModal,
    changeIsEdit,
    setSelectedWorkshopCycle,
    setSelectedStudentsNotInWorkshopCycle,
    setEditCycle,
    setSelectedProgram,
    setSelectedTeacher,
    getStudentByWorkshopCycle,
    getStudentByWorkshopCycleSearch,
    deleteWorkshopCycleStudent,
    createRelationStudentWorkshopCycle,
    fetchStudentByWorkshopCycle,
    editStudentWorkshopCycleStore,
  };
};
