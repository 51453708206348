import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "../redux/store";
import { AuthStatus, LocalStorageKey, LoginType } from "../types";
import {
  readLocalStorage,
  saveLocalStorage,
} from "../toolbox/helpers/local-storage-helpers";
import {
  onChangeAuthStatus,
  onLogin,
  onLogout,
  onRefreshToken,
  onVerifying,
  onChangeAuthStatusUser,
} from "../redux/slices/authSlice";
import { AuthAPI } from "../apis/AuthAPI";
import { ProfileAPI } from "../apis/ProfileAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ApiStatus } from "../types/api/status";

export const useAuthStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { status, user, errorMessage, statusUser } = useSelector(
    (state) => state.authSlice
  );

  const setProfileData = async (access_token: string, iduser_type?: number) => {
    try {
      const perfil = await ProfileAPI.get(access_token);
      const { detail } = perfil;
      saveLocalStorage(LocalStorageKey.USER_DATA, detail);
      dispatch(onLogin(detail));
      return detail;
    } catch (error) {
      console.error(error);
    }
  };

  const login = async ({ dni, iduser_type, password }: LoginType) => {
    try {
      const login = await AuthAPI.login(iduser_type, password, dni);
      if (!login?.detail?.access_token) {
        CustomSnackbar("error", login.message || "Credenciales Incorrectas");
        return { status: false };
      }
      const { access_token } = login.detail;
      saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token);

      const detail = await setProfileData(access_token, iduser_type);
      CustomSnackbar("success", "Bienvenido");

      return {
        status: true,
        detail,
      };
    } catch (error) {
      dispatch(onLogout("Credenciales Incorrectas"));
      CustomSnackbar("error", "Credenciales Incorrectas");
      return {
        status: false,
        detail: {},
      };
    }
  };

  const verifySession = async () => {
    dispatch(onVerifying());
    const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE);
    if (!token) return dispatch(onLogout());
    const isValidToken = await AuthAPI.verifyToken(token);
    try {
      if (isValidToken) {
        await setProfileData(token);
        dispatch(onChangeAuthStatus(AuthStatus.AUTHENTICATED));
        navigate({ pathname: location.pathname });
        return;
      } else {
        await refreshToken(token);
      }
      localStorage.clear();
      dispatch(onLogout());
      CustomSnackbar("error", "Su sesión expiró");
    } catch (error) {
      CustomSnackbar("error", "Su sesión expiró");
      localStorage.clear();
      dispatch(onLogout());
    }
  };

  const refreshToken = async (token: string) => {
    try {
      const session = await AuthAPI.refresh(token);
      if (!session?.detail?.token) {
        localStorage.clear();
        dispatch(onLogout());
        CustomSnackbar("error", "Su sesión expiró");
      } else {
        const { token, user } = session.detail;
        await setProfileData(token);
        saveLocalStorage(LocalStorageKey.TOKEN_KYTE, token);
        dispatch(onRefreshToken(user));

        navigate({ pathname: location.pathname });
      }
    } catch (error) {
      console.error("[Error whilte refreshing token]", error);
    }
  };

  const logout = async () => {
    const logout = await AuthAPI.logout();
    const { detail } = logout;
    CustomSnackbar("success", detail || "Sesión cerrada con éxito");
    localStorage.clear();
    dispatch(onLogout());
  };

  const changePassword = async (data: any) => {
    try {
      dispatch(onChangeAuthStatusUser(ApiStatus.FETCHING));
      const response = await AuthAPI.changePassword(data);
      if (!response?.status) {
        dispatch(onChangeAuthStatusUser(ApiStatus.FETCHED));
        CustomSnackbar(
          "error",
          response.data.message || "ocurrió algún error, intentelo mas tarde"
        );
        return false;
      }
      dispatch(onChangeAuthStatusUser(ApiStatus.FETCHED));
      CustomSnackbar("success", response.data.message || "");
      return true;
    } catch (error) {
      console.log(error);
      return dispatch(onChangeAuthStatusUser(ApiStatus.FETCHED));
    }
  };

  return {
    /* states */
    status,
    statusUser,
    user,
    errorMessage,
    /* actions */
    login,
    logout,
    verifySession,
    setProfileData,
    changePassword,
  };
};
