import { Autocomplete, FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { useEffect } from "react";
import { useCycleStore } from "../../../hooks/useCycleStore";
import { Student } from "../../../types/slices/studentType";

export const AddStudentCycleModal: React.FC<{
  onAddStudent: (data: any) => void;
}> = ({ onAddStudent }): JSX.Element | any => {
  const {
    studentsNotInCycle,
    selectedStudentsNotInCycle,
    selectedCycle,
    openModal,
    setOpenModal,
    isEdit,
    getStudentByCycle,
    getStudentNotInCycle,
    setSelectedStudentsNotInCycle,
    createRelationStudentCycle,
  } = useCycleStore();

  const handleSubmit = async () => {
    setOpenModal(false);
    let idArray: any = selectedStudentsNotInCycle.map((obj) => obj.id);
    idArray = JSON.stringify(idArray);
    const response = await createRelationStudentCycle(
      selectedCycle.id || 0,
      idArray
    );
    if (response === true) {
      getStudentByCycle(selectedCycle.id || 0).then((data) =>
        onAddStudent(data)
      );
      setSelectedStudentsNotInCycle([]);
    }
  };

  useEffect(() => {
    if (openModal) {
      setSelectedStudentsNotInCycle([]);
      getStudentNotInCycle(selectedCycle.id || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, selectedCycle.id]);

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={"Agregar Alumnos"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditeditCycle}
        ></ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <FormLabel>Alumnos:</FormLabel>
                {studentsNotInCycle.length > 0 && (
                  <Autocomplete
                    id="combo-box-course"
                    value={selectedStudentsNotInCycle}
                    multiple
                    limitTags={10}
                    options={studentsNotInCycle || []}
                    getOptionLabel={(option) =>
                      option?.dni +
                        " - " +
                        option?.name +
                        " " +
                        option?.surname || ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(e, newValue) => {
                      setSelectedStudentsNotInCycle(
                        newValue || ([] as Student[])
                      );
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter
          confirmText={isEdit ? "Actualizar" : "Registrar"}
          onConfirm={handleSubmit}
          cancelText={"Cancelar"}
          onCancel={() => {
            setOpenModal(false);
          }}
          className="modal-confirm-footer"
        />
      </Modal>
    </>
  );
};
