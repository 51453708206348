import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Workshop, WorkshopsType } from "../../types/slices/workshopsType";

const initialState: WorkshopsType = {
  statusWorkshops: ApiStatus.FETCHED,
  workshops: [],
  errorMessage: undefined,
  editWorkshop: {} as Workshop,
  selectedWorkshop: {} as Workshop,
  isEdit: false,
  openModal: false,
};

const workshopSlice = createSlice({
  name: "workshop",
  initialState,
  reducers: {
    onFetchWorkshop(state, { payload }: { payload: Workshop[] }) {
      state.statusWorkshops = ApiStatus.FETCHED;
      state.workshops = payload;
      state.errorMessage = undefined;
    },
    changeStatusWorkshops(state, { payload }: { payload: ApiStatus }) {
      state.statusWorkshops = payload;
    },
    onSetEditWorkshop(state, { payload }: { payload: Workshop }) {
      state.editWorkshop = payload;
    },
    onSetSelectedWorkshop(state, { payload }: { payload: Workshop }) {
      state.selectedWorkshop = payload;
    },
    onSetOpenModal(state, { payload }: { payload: boolean }) {
      state.openModal = payload;
    },
    onChangeIsEdit(state, { payload }: { payload: boolean }) {
      state.isEdit = payload;
    },
  },
});

export const selectWorkshopState = (state: RootState) => state.workshopSlice;
export default workshopSlice.reducer;

export const {
  onFetchWorkshop,
  changeStatusWorkshops,
  onSetEditWorkshop,
  onSetSelectedWorkshop,
  onSetOpenModal,
  onChangeIsEdit,
} = workshopSlice.actions;
