import { FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useProgramStore } from "../../../hooks/useProgramStore";
import { Program } from "../../../types/slices/programType";

export const CreateEditProgramModal: React.FC<any> = (): JSX.Element | any => {
  const {
    editProgram,
    openModal,
    setOpenModal,
    isEdit,
    createProgramstore,
    editProgramstore,
    getPrograms,
  } = useProgramStore();

  const [data, setData] = useState<Program>({
    name: "",
    status: "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.name) errors.name = "Nombre es requerido";
    if (values.name && values.name.length > 200)
      errors.name = "El número máximo de caracteres es 200";
    return errors;
  };

  const onSubmit = async (values) => {
    const method = isEdit
      ? editProgramstore(editProgram?.id || 0, values)
      : createProgramstore(values);
    const response = await method;
    if (response === true) {
      getPrograms();
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setData(editProgram);
    } else {
      setData({
        name: "",
        status: "",
      });
    }
  }, [isEdit, editProgram]);

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Programa" : "Crear Programa"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditStudent}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Nombre:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                  // loadingConfirmText={status2}
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
