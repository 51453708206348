import { FC, useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRewardStudentStore } from "../../hooks/useRewardStudentStore";
import {
  RewardStudent,
  RewardStudentList,
} from "../../types/slices/rewardStudentType";
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { onSetSelectedRewardStudentList } from "../../redux/slices/rewardStudentSlice";
import { CreateEditRewardStudentModal } from "./components/CreateEditRewardStudentModal";

const columns = [
  {
    type: "options",
    field: "options",
    label: "Opciones",
    isVisible: (row) => row.concept === "Canje",
  },
  {
    type: "text",
    field: "issue_date",
    label: "Fecha",
  },
  {
    type: "text",
    field: "concept",
    label: "Concepto",
  },
  {
    type: "input",
    field: "points",
    label: "Puntos",
    isVisible: (row) => row.concept === "Canje",
  },
];

const RewardStudentDetail: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<RewardStudent>(
    {} as RewardStudent
  );
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

  const {
    rewardStudent,
    statusRewardStudent,
    openModal,
    selectedRewardStudentList,
    setSelectedRewardStudent,
    deleteRewardStudent,
    editRewardStudent,
    setOpenModal,
    getRewardStudent,
    setRewardStudent,
  } = useRewardStudentStore();

  useEffect(() => {
    if (selectedRewardStudentList.id) {
      setLoading(true);
      getRewardStudent(selectedRewardStudentList.id).then((data) => {
        setLoading(false);
      });
    } else {
      navigate(RoutesMap.REWARDSTUDENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRewardStudentList, setLoading]);

  const onDiscount = () => {
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteRewardStudent(rowSelected?.id || 0);
    if (response === true) {
      setSelectedRewardStudent({} as RewardStudent);
      setModalDeleteConfirm(false);
      getRewardStudent(selectedRewardStudentList.id);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goBack = () => {
    onSetSelectedRewardStudentList({} as RewardStudentList);
    navigate(RoutesMap.REWARDSTUDENT);
  };

  const onClear = () => {
    getRewardStudent(selectedRewardStudentList.id || 0).then(
      (data) => {}
      // setStudentsByCycleData(data)
    );
  };

  const onSave = async () => {
    await editRewardStudent(rowSelected?.id || 0, {
      points: rowSelected?.points,
    });
    onClear();
    setRowSelected({} as RewardStudent);
  };

  const onChange = (value, updatedRow, field) => {
    setRowSelected({ ...rowSelected, points: Number(value) });

    const foundIndex = rewardStudent.findIndex(
      (row) => row.id === updatedRow.id
    );

    if (foundIndex !== -1) {
      const newRewarStudent: any = [...rewardStudent];

      newRewarStudent[foundIndex] = {
        ...newRewarStudent[foundIndex],
        [field]: value,
      };

      setRewardStudent(newRewarStudent);
    }
  };

  return (
    <>
      {statusRewardStudent === ApiStatus.FETCHING && (
        <CustomBackdrop open={true} />
      )}{" "}
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{ marginLeft: "-25px" }}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="view"
          onClick={() => {
            goBack();
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography>Volver</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          hasOptions
          editable
          onClear={onClear}
          onSave={onSave}
          conditionalOptionsBy={"Canje"}
          title={`${selectedRewardStudentList.name}: puntos acumulados`}
          columns={columns}
          loading={loading}
          rows={rewardStudent || []}
          onRowClick={() => {}}
          onAddFn={onDiscount}
          onDelete={onDelete}
          rowsPerPage={rowsPerPage}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          page={page}
          onChangeInput={onChange}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusRewardStudent}
        />
      )}
      {openModal && <CreateEditRewardStudentModal />}
    </>
  );
};

export default RewardStudentDetail;
