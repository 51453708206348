import { api } from "./configs/axiosConfigs";

export const RewardAPI = {
  getRewards: async () => {
    const response = await api
      .get("/reward")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getRewardsSearch: async (term) => {
    const response = await api
      .get(`/reward?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createReward: async (data: any) => {
    const response = await api
      .post("/reward", { ...data, status: "1" })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editReward: async (id: number, data: any) => {
    const response = await api
      .patch(`/reward/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteReward: async (id: number) => {
    const response = await api
      .delete(`/reward/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },
};
