import { FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useState } from "react";
import { RewardStudent } from "../../../types/slices/rewardStudentType";
import { useRewardStudentStore } from "../../../hooks/useRewardStudentStore";

export const CreateEditRewardStudentModal: React.FC<any> = ():
  | JSX.Element
  | any => {
  const {
    discountRewardStudentStore,
    getRewardStudent,
    selectedRewardStudentList,
    openModal,
    setOpenModal,
    isEdit,
  } = useRewardStudentStore();

  const [data] = useState<RewardStudent>({
    idStudent: selectedRewardStudentList.id,
    concept: "Canje",
    points: 0,
    issueDate: new Date(),
    status: "1",
  });

  const validateForm = (values) => {
    let errors: any = {};

    if (!values.points || values.points === 0) {
      errors.points = "Los puntos de canje debe ser mayor a 0";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const method = discountRewardStudentStore(values);
    const response = await method;
    if (response === true) {
      getRewardStudent(selectedRewardStudentList.id);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Canje" : "Agregar Canje"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Concepto:</FormLabel>
                          <TextField
                            id="concept"
                            type="text"
                            name="concept"
                            fullWidth
                            size="small"
                            value={values.concept}
                            aria-readonly
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Puntos:</FormLabel>
                          <TextField
                            id="points"
                            type="number"
                            name="points"
                            fullWidth
                            size="small"
                            value={values.points}
                            onChange={handleChange}
                            error={
                              errors.points && touched.points ? true : false
                            }
                            helperText={
                              errors.points && touched.points
                                ? errors.points
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Canjear"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
