import { FC, useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useCourseStore } from "../../hooks/useCourseStore";
import { Course, Module } from "../../types/slices/coursesType";
import { CreateEditModuleModal } from "./components/CreateEditModuleModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "name", label: "NOMBRE" },
  { type: "detail", field: "", label: "" },
];

const Modules: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Module>({} as Module);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

  const {
    statusModules,
    modules,
    selectedCourse,
    openModal,
    getModulesByCourse,
    setSelectedModule,
    deleteModule,
    setOpenModal,
    setEditModule,
    changeIsEdit,
    setSelectedCourse,
  } = useCourseStore();

  useEffect(() => {
    if (selectedCourse.id) {
      setLoading(true);
      getModulesByCourse(selectedCourse.id).then(() => setLoading(false));
    } else {
      navigate(RoutesMap.COURSES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditModule(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDetails = (rowSelected) => {
    setSelectedModule(rowSelected);
    navigate(RoutesMap.THEMES_OF_COURSES);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteModule(rowSelected?.id || 0);
    if (response === true) {
      setSelectedModule({} as Module);
      setModalDeleteConfirm(false);
      getModulesByCourse(selectedCourse.id);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goBack = () => {
    setSelectedCourse({} as Course);
    navigate(RoutesMap.COURSES);
  };

  return (
    <>
      {statusModules === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{ marginLeft: "-25px" }}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="view"
          onClick={() => {
            goBack();
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography>Volver</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          title={
            selectedCourse
              ? "Modulos del curso " + selectedCourse.name
              : "Modulos"
          }
          columns={columns}
          loading={loading}
          rows={modules || []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onDetails={onDetails}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusModules}
        />
      )}
      {openModal && <CreateEditModuleModal />}
    </>
  );
};

export default Modules;
