import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "./PrimaryButton";
import { StandardTextField } from "./StandardTextField";
import { FC, useMemo, useState } from "react";

interface SearchFormProps {
  onClick: (dni: string) => Promise<void>;
}

export const SearchForm: FC<SearchFormProps> = ({
  onClick,
}: SearchFormProps) => {
  const [dni, setDni] = useState<string>("");

  return (
    <>
      <br />
      <br />
      <br />
      <Typography variant="h4">¡Bienvenido!</Typography>
      <div style={{ color: "#5F616E", fontSize: 17, textAlign: "center" }}>
        En este apartado podrás descargar tus certificados y constancias
      </div>
      <br />
      <Box component="form" noValidate sx={{ mt: 2 }}>
        <StandardTextField
          variant="standard"
          margin="normal"
          fullWidth
          id="dni"
          placeholder="Ingresa tu DNI o CE"
          name="dni"
          autoComplete=""
          autoFocus
          value={dni}
          onChange={(v) => setDni(v.target.value)}
        />
        <PrimaryButton
          fullWidth
          variant="contained"
          className="Button"
          onClick={() => onClick(dni)}
        >
          Buscar
        </PrimaryButton>
      </Box>
    </>
  );
};

export const MoreCoursesOrWorkshops = ({ message }: { message?: string }) => {
  const displayMessage = useMemo(() => {
    if (!message) {
      return "Descubre todos los cursos por nuestro canal de whatsapp";
    }
    return message;
  }, [message]);

  return (
    <Box
      style={{
        textAlign: "center",
        padding: 30,
        maxWidth: 520,
        margin: "0 auto",
      }}
    >
      {message && (
        <Typography
          variant="h4"
          component="h4"
          style={{ fontSize: 28, fontWeight: 600 }}
        >
          <img
            width="50"
            alt="canal de whatsapp"
            src="./icons/sad-face-icon.svg"
          />
          <br />
          ¡Es hora de comenzar!
        </Typography>
      )}
      <div style={{ fontStyle: "italic" }}>{displayMessage}</div>
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://whatsapp.com/channel/0029VaDySzwFHWq0sH7IvR12"
      >
        <img
          width="50"
          alt="canal de whatsapp"
          src="./icons/whatsapp-channel-icon.png"
        />
      </a>
    </Box>
  );
};
