import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useRewardStudentStore } from "../../hooks/useRewardStudentStore";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";

const columns = [
  {
    type: "text",
    field: "student",
    label: "Alumno",
    format: ({ name, surname }) => `${name} ${surname}`,
  },
  { type: "text", field: "points", label: "Puntos acumulados" },
  { type: "detail", field: "", label: "" },
];

const RewardStudentList: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const {
    statusRewardStudent,
    rewardStudentList,
    setSelectedRewardStudentList,
    getRewardStudentList,
    getRewardStudentListSearch,
  } = useRewardStudentStore();

  useEffect(() => {
    setLoading(true);
    getRewardStudentList().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = (value) => {
    if (value.length >= 1) {
      getRewardStudentListSearch(value);
    }
    if (value.length === 0) {
      getRewardStudentList();
    }
  };

  const onDetails = (rowSelected) => {
    setSelectedRewardStudentList(rowSelected);
    navigate(RoutesMap.REWARDSTUDENTDETAIL);
  };

  return (
    <>
      {statusRewardStudent === ApiStatus.FETCHING && (
        <CustomBackdrop open={true} />
      )}
      <Grid item xs={12}>
        <CustomTable
          title={"Puntos Acumulados"}
          columns={columns}
          loading={loading}
          rows={rewardStudentList || []}
          onRowClick={() => {}}
          hasOptions
          onSearch={onSearch}
          rowsPerPage={rowsPerPage}
          page={page}
          onDetails={onDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
};

export default RewardStudentList;
