import { FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCourseStore } from "../../../hooks/useCourseStore";
import { Course } from "../../../types/slices/coursesType";

export const CreateEditCourseModal: React.FC<any> = (): JSX.Element | any => {
  const {
    editCourse,
    openModal,
    setOpenModal,
    isEdit,
    createCourseStore,
    editCourseStore,
    getCourses,
  } = useCourseStore();

  const [data, setData] = useState<Course>({
    name: "",
    code: "",
    n_modules: 0,
    n_academic_hours: 0,
  });

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.name) errors.name = "Nombre es requerido";
    if (values.name && values.name.length > 200)
      errors.name = "El número máximo de caracteres es 200";
    if (!values.code) errors.code = "Código es requerido";
    if (values.code && values.code.length > 10)
      errors.code = "El número máximo de caracteres es 10";
    if (!values.n_modules) errors.n_modules = "N° de modulos es requerido";
    if (parseInt(values.n_modules) <= 0)
      errors.n_modules = "No puede ingresar números menor o igual a 0";
    if (!values.n_academic_hours)
      errors.n_academic_hours = "N° de horas académicas es requerido";
    if (parseInt(values.n_academic_hours) <= 0)
      errors.n_academic_hours = "No puede ingresar números menor o igual a 0";

    return errors;
  };

  const onSubmit = async (values) => {
    const method = isEdit
      ? editCourseStore(editCourse?.id || 0, values)
      : createCourseStore(values);
    const response = await method;
    if (response === true) {
      getCourses();
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setData(editCourse);
    } else {
      setData({
        name: "",
        code: "",
        n_modules: 0,
        n_academic_hours: 0,
      });
    }
  }, [isEdit, editCourse]);

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Curso" : "Crear Curso"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditStudent}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Nombre:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Código:</FormLabel>
                          <TextField
                            id="code"
                            type="text"
                            name="code"
                            fullWidth
                            size="small"
                            value={values.code}
                            onChange={handleChange}
                            error={errors.code && touched.code ? true : false}
                            helperText={
                              errors.code && touched.code ? errors.code : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>N° Módulos:</FormLabel>
                          <TextField
                            id="n_modules"
                            type="number"
                            name="n_modules"
                            fullWidth
                            size="small"
                            value={values.n_modules}
                            onChange={handleChange}
                            error={
                              errors.n_modules && touched.n_modules
                                ? true
                                : false
                            }
                            helperText={
                              errors.n_modules && touched.n_modules
                                ? errors.n_modules
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>N° Horas académicas:</FormLabel>
                          <TextField
                            id="n_academic_hours"
                            type="number"
                            name="n_academic_hours"
                            fullWidth
                            size="small"
                            value={values.n_academic_hours}
                            onChange={handleChange}
                            error={
                              errors.n_academic_hours &&
                              touched.n_academic_hours
                                ? true
                                : false
                            }
                            helperText={
                              errors.n_academic_hours &&
                              touched.n_academic_hours
                                ? errors.n_academic_hours
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                  // loadingConfirmText={status2}
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
