import { api } from "./configs/axiosConfigs";

export const WorkshopDocumentAPI = {
  getWorkshopDocument: async () => {
    const response = await api
      .get(`/workshopDocument?status=1`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getWorkshopDocumentSearch: async (term) => {
    const response = await api
      .get(`/workshopDocument?status=1&term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getWorkshopDocumentByDNI: async (dni) => {
    const response = await api
      .get(`/workshopDocument/${dni}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDF: async (id) => {
    const response = await api
      .get(`/workshopDocument/pdf/${id}`, { responseType: "blob" })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFTemplate: async (id) => {
    const response = await api
      .get(`/workshopDocument/pdf-template/${id}`, { responseType: "blob" })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFMultipleTemplate: async (
    idcycle,
    ids,
    type: string,
    side = "both"
  ) => {
    const response = await api
      .post(
        `/workshopDocument/pdf-multiple/${idcycle}`,
        {
          ids,
          type,
          side,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFByUrl: async (id) => {
    const response = await api
      .get(`/workshopDocument/pdf-url/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
};
