import { api } from "./configs/axiosConfigs";

export const WorkshopAPI = {
  //workshops
  getWorkshops: async () => {
    const response = await api
      .get("/workshop")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getWorkshopsSearch: async (term) => {
    const response = await api
      .get(`/workshop?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createWorkshop: async (data: any) => {
    const response = await api
      .post("/workshop", { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editWorkshop: async (id: number, data: any) => {
    const response = await api
      .patch(`/workshop/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteWorkshop: async (id: number) => {
    const response = await api
      .delete(`/workshop/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },
};
