export enum RoutesMap {
  HOME = "/home",
  LOGIN = "/login",
  MAIN = "/main",
  MY_PROFILE = "/my-profile",

  /* ------------------------- VIEWS ADMIN ------------------------------- */

  STUDENTS = "/alumnos",
  PROGRAMS = "/programas",
  REWARDS = "/concepto-por-puntos",
  REWARDSTUDENT = "/puntos-acumulados",
  REWARDSTUDENTDETAIL = "/puntos-acumulados-detalle",
  COURSES = "/cursos",
  WORKSHOPS = "/talleres",
  MODULES_OF_COURSES = "/modulos-cursos",
  THEMES_OF_COURSES = "/temas-cursos",
  WORKSHOP_CYCLE = "/taller-ciclos",
  CYCLE = "/ciclos",
  ALUMNOS_OF_CYCLE = "/alumnos-ciclo",
  ALUMNOS_OF_WORKSHOP_CYCLE = "/alumnos-taller-ciclo",
  CERTIFICATES = "/certificados",
  WORKSHOP_DOCUMENTS = "/constancias",
}
