import { Autocomplete, FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Teacher } from "../../../types/slices/cycleType";
import { Course } from "../../../types/slices/coursesType";
import { Program } from "../../../types/slices/programType";
import { useWorkshopCycleStore } from "../../../hooks/useWorkshopCycleStore";
import { WorkshopCycle } from "../../../types/slices/workshopCycleType";
import { useWorkshopStore } from "../../../hooks/useWorkshopStore";
import { Workshop } from "../../../types/slices/workshopsType";

export const CreateEditWorkshopCycleModal: React.FC<any> = ():
  | JSX.Element
  | any => {
  const {
    editWorkshopCycle,
    openModal,
    setOpenModal,
    isEdit,
    createWorkshopCycleStore,
    editWorkshopCycleStore,
    getWorkshopCycle,
    getTeacher,
    getProgram,
    setSelectedTeacher,
    setSelectedProgram,
    selectedTeacher,
    selectedProgram,
    teachers,
    programs,
  } = useWorkshopCycleStore();
  const { workshops, getWorkshops, selectedWorkshop, setSelectedWorkshop } =
    useWorkshopStore();
  const [data, setData] = useState<WorkshopCycle>({
    name: "",
    teacher: "",
    date_start: "",
    date_end: "",
    workshop: "",
    idworkshop: "",
    program: "",
    idprogram: "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.name) errors.name = "Nombre es requerido";
    if (!values.idworkshop) errors.idworkshop = "Taller es requerido";
    if (!values.idprogram) errors.idprogram = "Programa es requerido";
    if (!values.date_start) errors.date_start = "Fecha es requerido";
    return errors;
  };

  const onSubmit = async (values) => {
    setOpenModal(false);
    let params: any = {
      name: values.name,
      idworkshop: selectedWorkshop.id,
      idprogram: selectedProgram.id,
      date_start: values.date_start,
      date_end: values.date_start,
    };

    if (selectedTeacher.id) {
      params = { ...params, idteacher: selectedTeacher.id };
    } else {
      params = { ...params, teacher: values.teacher };
    }
    const method = isEdit
      ? editWorkshopCycleStore(editWorkshopCycle?.id || 0, params)
      : createWorkshopCycleStore(params);
    const response = await method;
    if (response === true) {
      getWorkshopCycle();
    }
  };

  useEffect(() => {
    getWorkshops();
    getProgram();
    getTeacher();
    if (isEdit) {
      setData(editWorkshopCycle);
      setSelectedWorkshop(editWorkshopCycle?.workshops || ({} as Course));
      setSelectedTeacher(editWorkshopCycle?.teachers || ({} as Teacher));
      setSelectedProgram(editWorkshopCycle?.programs || ({} as Program));
    } else {
      setData({
        name: "",
        idworkshop: 0,
        idteacher: 0,
        idprogram: 0,
        date_start: "",
        date_end: "",
      });
      setSelectedWorkshop({} as Course);
      setSelectedTeacher({} as Teacher);
      setSelectedProgram({} as Program);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, editWorkshopCycle]);

  const onChangeTeacher = (newValue: Teacher | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idteacher", newValue.id);
      setSelectedTeacher(newValue);
    } else {
      setFieldValue("idteacher", "");
      setSelectedTeacher({} as Teacher);
    }
  };

  const onChangeProgram = (newValue: Program | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idprogram", newValue.id);
      setSelectedProgram(newValue);
    } else {
      setFieldValue("idprogram", "");
      setSelectedProgram({} as Program);
    }
  };

  const onChangeWorkshop = (newValue: Workshop | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idworkshop", newValue.id);
      setSelectedWorkshop(newValue);
    } else {
      setFieldValue("idworkshop", "");
      setSelectedWorkshop({} as Course);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Ciclo" : "Crear Ciclo"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditeditCycle}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) =>
            validateForm({ ...values, selectedWorkshop: selectedWorkshop })
          }
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Programa:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedProgram}
                            options={programs || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idprogram ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeProgram(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Nombre Ciclo:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Profesor Existente:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedTeacher}
                            options={teachers || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idteacher ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeTeacher(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Profesor Nuevo:</FormLabel>
                          <TextField
                            id="teacher"
                            type="text"
                            name="teacher"
                            fullWidth
                            size="small"
                            value={values.teacher}
                            onChange={handleChange}
                            error={
                              errors.teacher && touched.teacher ? true : false
                            }
                            helperText={
                              errors.teacher && touched.teacher
                                ? errors.teacher
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Talleres:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedWorkshop}
                            options={workshops || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idworkshop ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeWorkshop(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Fecha:</FormLabel>
                          <TextField
                            id="date_start"
                            type="date"
                            name="date_start"
                            fullWidth
                            size="small"
                            value={values.date_start}
                            onChange={handleChange}
                            error={
                              errors.date_start && touched.date_start
                                ? true
                                : false
                            }
                            helperText={
                              errors.date_start && touched.date_start
                                ? errors.date_start
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                  // loadingConfirmText={status2}
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
