import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useRewardStore } from "../../hooks/useRewardStore";
// import { CreateEditRewardModal } from "./components/CreateEditRewardModal";
import { Reward } from "../../types/slices/rewardType";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "input", field: "points", label: "Puntos" },
  { type: "text", field: "concept", label: "Concepto" },
  { type: "text", field: "note", label: "Nota" },
  {
    type: "text",
    field: "status",
    label: "Estado",
    format: (row) => (row.status === "1" ? "Activo" : "Inactivo"),
  },
];

const Rewards: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Reward>({} as Reward);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);

  const {
    rewards,
    statusRewards,
    getRewards,
    getRewardsSearch,
    setSelectedReward,
    deleteReward,
    setRewards,
    editRewardstore,
  } = useRewardStore();

  useEffect(() => {
    setLoading(true);
    getRewards().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteConfirm = async () => {
    const response = await deleteReward(rowSelected?.id || 0);
    if (response === true) {
      setSelectedReward({} as Reward);
      setModalDeleteConfirm(false);
      getRewards();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = (value) => {
    if (value.length >= 1) {
      getRewardsSearch(value);
    }
    if (value.length === 0) {
      getRewards();
    }
  };

  const onClear = () => {
    if (!isSearch) {
      getRewards().then(
        (data) => {}
        // setStudentsByCycleData(data)
      );
    }
  };

  const onSave = async () => {
    await editRewardstore(rowSelected?.id || 0, {
      points: rowSelected?.points,
    });
    onClear();
    setRowSelected({} as Reward);
  };

  const onChange = (value, updatedRow, field) => {
    setRowSelected({ ...rowSelected, points: Number(value) });

    const foundIndex = rewards.findIndex((row) => row.id === updatedRow.id);

    if (foundIndex !== -1) {
      const newRewarStudent: any = [...rewards];

      newRewarStudent[foundIndex] = {
        ...newRewarStudent[foundIndex],
        [field]: value,
      };

      setRewards(newRewarStudent);
    }
  };

  return (
    <>
      {statusRewards === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Puntos por Concepto"}
          columns={columns}
          loading={loading}
          rows={rewards || []}
          onRowClick={() => {}}
          hasOptions
          noDelete
          editable
          onClear={onClear}
          onSave={onSave}
          onSearch={onSearch}
          rowsPerPage={rowsPerPage}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          page={page}
          onChangeInput={onChange}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusRewards}
        />
      )}
      {/* {openModal && <CreateEditRewardModal />} */}
    </>
  );
};

export default Rewards;
