import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  show: false,
  alertType: "",
  message: "",
  actions: [],
};

const snackbarSlice = createSlice({
  name: "snackbarSlice",
  initialState,
  reducers: {
    onShowSnackBar(state, { payload }: { payload: any | undefined }) {
      state.show = true;
      state.alertType = payload.alertType;
      state.message = payload.message;
    },
    onHideSnackBar(state) {
      state.show = false;
      state.alertType = "";
      state.message = "";
    },
  },
});

export const { onShowSnackBar, onHideSnackBar } = snackbarSlice.actions;
export const selectAuth = (state: any) => state.snackbarSlice;
export default snackbarSlice.reducer;
