import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthStore } from "./hooks/useAuthStore";
import { AuthStatus } from "./types/slices/authType";
import { Backdrop, CircularProgress } from "@mui/material";
import { RoutesMap } from "./types/common/routes";
import { HomeView } from "./views/Home/HomeView";
import { LoginView } from "./views/Login/LoginView";
import { RestrictedRoute } from "./routes";
import { Protected } from "./components/Layout/Protected";
import { useSelector } from "./redux/store";
import { CustomBackdrop } from "./components/common/CustomBackdrop/CustomBackdrop";
import { MyProfileView } from "./views/MyProfile/MyProfile";
import "./App.css"; //no comentar
import Students from "./views/Students/Students";
import Courses from "./views/Courses/Courses";
import Modules from "./views/Courses/Modules";
import Themes from "./views/Courses/Themes";
import { Toaster } from "react-hot-toast";
import CycleView from "./views/Cycle/Cycle";
import StudentCycle from "./views/Cycle/StudentCycle";
import CertificatesView from "./views/Certificate/Certificate";
import Programs from "./views/Program/Programs";
import Workshops from "./views/Workshops/Workshops";
import WorkshopCycleView from "./views/WorkshopCycle/WorkshopCycle";
import WrokshopDocumentsView from "./views/WorkshopDocument/WorkshopDocument";
import StudentWorkshopCycle from "./views/WorkshopCycle/StudentWorkshopCycle";
import Rewards from "./views/Reward/Rewards";
import RewardStudentList from "./views/RewardStudent/RewardStudentList";
import RewardStudentDetail from "./views/RewardStudent/RewardStudentDetail";

function App() {
  /* hooks */
  const { verifySession, status } = useAuthStore();
  const { showBackdrop } = useSelector((state) => state.backdropSlice);

  useEffect(() => {
    verifySession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === AuthStatus.VERIFYING) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Routes>
        {/* RUTAS PUBLICAS */}
        {/* <Route
                    path="/"
                    element={<Navigate to={RoutesMap.MAIN} replace />}
                /> */}

        {/* RUTAS PRIVADAS */}
        {status === AuthStatus.NOT_AUTHENTICATED ? (
          <>
            <Route path="/" element={<HomeView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/*" element={<Navigate to={"/login"} replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Protected />}>
              {/* ------------------------- VIEWS ADMIN ------------------------------- */}
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.CYCLE} />}
              >
                <Route path={RoutesMap.CYCLE} element={<CycleView />} />
              </Route>

              {/*STUDENT */}
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.STUDENTS} />}
              >
                <Route path={RoutesMap.STUDENTS} element={<Students />} />
              </Route>

              {/*COURSES */}
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.REWARDS} />}
              >
                <Route path={RoutesMap.REWARDS} element={<Rewards />} />
              </Route>
              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.REWARDSTUDENT} />
                }
              >
                <Route
                  path={RoutesMap.REWARDSTUDENT}
                  element={<RewardStudentList />}
                />
              </Route>
              <Route
                element={
                  <RestrictedRoute
                    pathRequired={RoutesMap.REWARDSTUDENTDETAIL}
                  />
                }
              >
                <Route
                  path={RoutesMap.REWARDSTUDENTDETAIL}
                  element={<RewardStudentDetail />}
                />
              </Route>
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.PROGRAMS} />}
              >
                <Route path={RoutesMap.PROGRAMS} element={<Programs />} />
              </Route>
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.COURSES} />}
              >
                <Route path={RoutesMap.COURSES} element={<Courses />} />
              </Route>
              <Route
                element={
                  <RestrictedRoute
                    pathRequired={RoutesMap.MODULES_OF_COURSES}
                  />
                }
              >
                <Route
                  path={RoutesMap.MODULES_OF_COURSES}
                  element={<Modules />}
                />
              </Route>
              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.THEMES_OF_COURSES} />
                }
              >
                <Route
                  path={RoutesMap.THEMES_OF_COURSES}
                  element={<Themes />}
                />
              </Route>

              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.ALUMNOS_OF_CYCLE} />
                }
              >
                <Route
                  path={RoutesMap.ALUMNOS_OF_CYCLE}
                  element={<StudentCycle />}
                />
              </Route>
              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.CERTIFICATES} />
                }
              >
                <Route
                  path={RoutesMap.CERTIFICATES}
                  element={<CertificatesView />}
                />
              </Route>
              <Route
                element={<RestrictedRoute pathRequired={RoutesMap.WORKSHOPS} />}
              >
                <Route path={RoutesMap.WORKSHOPS} element={<Workshops />} />
              </Route>
              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.WORKSHOP_CYCLE} />
                }
              >
                <Route
                  path={RoutesMap.WORKSHOP_CYCLE}
                  element={<WorkshopCycleView />}
                />
              </Route>
              <Route
                element={
                  <RestrictedRoute
                    pathRequired={RoutesMap.WORKSHOP_DOCUMENTS}
                  />
                }
              >
                <Route
                  path={RoutesMap.WORKSHOP_DOCUMENTS}
                  element={<WrokshopDocumentsView />}
                />
              </Route>

              <Route
                element={
                  <RestrictedRoute
                    pathRequired={RoutesMap.ALUMNOS_OF_WORKSHOP_CYCLE}
                  />
                }
              >
                <Route
                  path={RoutesMap.ALUMNOS_OF_WORKSHOP_CYCLE}
                  element={<StudentWorkshopCycle />}
                />
              </Route>
              {/* PROFILE */}
              <Route
                element={
                  <RestrictedRoute pathRequired={RoutesMap.MY_PROFILE} />
                }
              >
                <Route
                  path={RoutesMap.MY_PROFILE}
                  element={<MyProfileView />}
                />
              </Route>
            </Route>
          </>
        )}
      </Routes>
      <CustomBackdrop open={showBackdrop || false} />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            background: "#c9c7c7",
            color: "#fff",
          },
        }}
      />
    </>
  );
}

export default App;
