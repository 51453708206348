import { api } from "./configs/axiosConfigs";

export const StudentAPI = {
  getStudents: async () => {
    const response = await api
      .get("/student")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
  getStudentsSearch: async (term) => {
    const response = await api
      .get(`/student?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createStudents: async (data: any) => {
    const response = await api
      .post("/student", { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editStudents: async (id: number, data: any) => {
    const response = await api
      .patch(`/student/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteStudents: async (id: number) => {
    const response = await api
      .delete(`/student/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },
};
