import { Box, Chip, Drawer } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuthStore } from "../../../hooks";
import useResponsive from "../../../hooks/useResponsive";
import { useDrawerStore } from "../../../hooks/useDrawerStore";
import { NavSection } from "./NavSection";
import { HeaderView } from "../Header/Header";
import { useNavSectionStore } from "../../../hooks/useNavSectionStore";

const themeAvenir = createTheme({
  typography: {
    fontFamily: "Avenir",
    fontSize: 15,
  },
});

const NAV_WIDTH = 280;

export const Nav: React.FC<any> = ({
  openNav,
  onCloseNav,
}): JSX.Element | any => {
  const { user } = useAuthStore();

  const isDesktop = useResponsive("up", "lg", "");
  const { drawerOpen, openDrawer } = useDrawerStore();
  const { navOpen } = useNavSectionStore();
  const { pathname } = useLocation();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const withNavOpen = navOpen ? NAV_WIDTH : 0;

  const renderContent = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* <img style={{ width: '150px'}} src={logonav}/> */}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <ThemeProvider theme={themeAvenir}>
          <Chip
            size="medium"
            label={user.userType}
            variant="outlined"
            sx={{ background: "#212D39", color: "#FFFFFF", fontSize: "16px" }}
          />
        </ThemeProvider>
      </Box>
      <NavSection />
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 }, width: { lg: withNavOpen } }}
    >
      <HeaderView />
      {/* <Drawer open variant="permanent" 
              PaperProps={{ sx: { width: withNavOpen, bgcolor: 'background.default', borderRightStyle: 'dashed'} }}
            >
              { renderContent }
            </Drawer> */}
      {isDesktop ? (
        navOpen ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: withNavOpen,
                bgcolor: "background.default",
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : null
      ) : (
        <Drawer
          open={drawerOpen}
          onClose={() => openDrawer(false)}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: NAV_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};
