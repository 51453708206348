import {
  Grid,
  Typography,
  styled,
  Link,
  GridProps,
  Box,
  Button,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BoxLogo } from "./BoxLogo";
import { BoxBody } from "./BoxBody";
import StudentCard from "./StudentCard";
import CertificateCard from "./CertificateCard";
import { MoreCoursesOrWorkshops, SearchForm } from "./SearchForm";
import { useStudentStore } from "../../../hooks/useStudentStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop";
import { SocialSection } from "./SocialSection";
import { Footer } from "./Footer";
import WorkshopCard from "./WorkshopCard";
import RewardsDetail from "./RewardsDetail";
import { RewardStudent } from "../../../types/slices/rewardStudentType";

const GridBody = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: { height: "100vh", overflow: "hidden scroll" },
}));

const GridContainer = styled(Grid)<GridProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "100vh",
  },
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
  },
}));

const Certificates = ({ dataCertificate }) => {
  const { getCertificatePDF, getCertificatePDFByURL } = useStudentStore();
  const downloadCertificate = async (id: string, codigo: string) => {
    const response = await getCertificatePDFByURL(id);

    if (response !== false) {
      if (response?.detail) {
        const bloblURL = `${process.env.REACT_APP_ROOT_URL}/${response?.detail}`;
        const win = window.open(bloblURL, "_blank");
        win?.focus();
      } else {
        try {
          const response = await getCertificatePDF(id);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(response);
          link.download = `certificado-${codigo}.pdf`;
          document.body.append(link);
          link.click();
          link.remove();
        } catch (e) {
          console.warn("No se pudo descargar. Volver a intentar.");
        }
      }
    }
  };

  return (
    <>
      {!dataCertificate.length && (
        <MoreCoursesOrWorkshops message="No cuentas con certificados, descubre nuestros nuevos cursos." />
      )}
      {Boolean(dataCertificate.length) && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              textAlign: "left",
              width: "90%",
              maxWidth: 750,
              margin: "0 auto 20px",
            }}
          >
            {dataCertificate.length} Certificado
            {dataCertificate.length === 1 ? "" : "s"}:
          </Typography>

          {dataCertificate.map((data: any, index: number) => {
            return (
              <CertificateCard
                key={index}
                data={data}
                onClick={downloadCertificate}
                baseColor={index % 2 === 0 ? "#002A6D" : "#0C46FF"}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const Workshops = ({ dataWorkshop }) => {
  const { getWorkshopPDF, getWorkshopPDFByURL } = useStudentStore();
  const downloadWorkshop = async (id: string, codigo: string) => {
    const response = await getWorkshopPDFByURL(id);

    if (response !== false) {
      if (response?.detail) {
        const bloblURL = `${process.env.REACT_APP_ROOT_URL}/${response?.detail}`;
        const win = window.open(bloblURL, "_blank");
        win?.focus();
      } else {
        try {
          const response = await getWorkshopPDF(id);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(response);
          link.download = `constancia-${codigo}.pdf`;
          document.body.append(link);
          link.click();
          link.remove();
        } catch (e) {
          console.warn("No se pudo descargar. Volver a intentar.");
        }
      }
    }
  };
  return (
    <>
      {!dataWorkshop.length && (
        <MoreCoursesOrWorkshops message="No cuentas con constancias, descubre nuestros nuevos talleres." />
      )}
      {Boolean(dataWorkshop.length) && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              textAlign: "left",
              width: "90%",
              maxWidth: 750,
              margin: "0 auto 20px",
            }}
          >
            {dataWorkshop.length} Constancia
            {dataWorkshop.length === 1 ? "" : "s"}:
          </Typography>

          {dataWorkshop.map((data: any, index: number) => {
            return (
              <WorkshopCard
                key={index}
                data={data}
                onClick={downloadWorkshop}
                baseColor={index % 2 === 0 ? "#002A6D" : "#0C46FF"}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const ResultTab = ({ dataCertificate, dataWorkshop }: any) => {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: 1000, typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              textAlign: "center",
              maxWidth: 950,
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
              variant="fullWidth"
            >
              <Tab label="Cursos" value="1" />
              <Tab label="Talleres" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Certificates dataCertificate={dataCertificate} />
          </TabPanel>
          <TabPanel value="2">
            <Workshops dataWorkshop={dataWorkshop} />
          </TabPanel>
        </TabContext>
      </Box>
      <br />
      <br />
    </>
  );
};

export const ResultLayout = () => {
  const {
    status: statusCertificate,
    getCertificateByDNI,
    getWorkshopByDNI,
    getRewardStudent,
  } = useStudentStore();

  const [dataCertificate, setDataCertificate] = useState<any[]>([]);
  const [dataWorkshop, setDataWorkshop] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setError] = useState<boolean>(false);
  const [showRewardsDetail, setShowRewardsDetail] = useState<boolean>(false);
  const [wonPoints, setWonPoints] = useState<RewardStudent[]>([]);
  const [exchangedPoints, setExchangedPoints] = useState<RewardStudent[]>([]);

  const handleSearchCertificate = useCallback(
    async (dni: string): Promise<void> => {
      setLoading(true);
      if (!dni) return;
      const responseCertificate = await getCertificateByDNI(dni);
      const responseWorkshop = await getWorkshopByDNI(dni);

      if (responseCertificate.status || responseCertificate.length > 0) {
        setDataCertificate(responseCertificate);
      } else {
        setError(true);
      }
      if (responseWorkshop.status || responseWorkshop.length > 0) {
        setDataWorkshop(responseWorkshop);
      } else {
        setError(true);
      }
      setLoading(false);
    },
    [
      getCertificateByDNI,
      getWorkshopByDNI,
      setLoading,
      setDataWorkshop,
      setDataCertificate,
      setError,
    ]
  );

  // TODO: Future feature
  // const directDownload = useCallback((response: Blob) => {
  //   const nav: any = window.navigator;
  //   if (nav && nav.msSaveOrOpenBlob) {
  //     nav.msSaveOrOpenBlob(response);
  //     return;
  //   }
  //   const data = window.URL.createObjectURL(response);
  //   const link = document.createElement("a");
  //   document.body.appendChild(link);
  //   link.href = data;
  //   link.download = "certificado.pdf";
  //   link.click();
  //   window.URL.revokeObjectURL(data);
  //   link.remove();
  // }, []);

  const onAnotherDNIClick = useCallback(() => {
    setDataCertificate([]);
    setDataWorkshop([]);
    setShowRewardsDetail(false);
  }, [setDataCertificate, setDataWorkshop]);

  const [totalPoints, setTotalPoints] = useState(0);

  const dataStudent = useMemo(
    () => dataCertificate[0] || dataWorkshop[0],
    [dataCertificate, dataWorkshop]
  );

  useEffect(() => {
    if (dataStudent && dataStudent.idstudent) {
      (async () =>
        await getRewardStudent(dataStudent.idstudent).then((data) => {
          if (data && data.detail) {
            const total = data.detail.reduce(
              (acc, { points }) => acc + points,
              0
            );
            setTotalPoints(total);
            const exchangeData = data.detail.filter(
              ({ concept }) => concept === "Canje"
            );
            const wonData = data.detail.filter(
              ({ concept }) => concept !== "Canje"
            );

            const mappedWonData = wonData.map((data) => ({
              ...data,
              issueDate: new Date(data.issue_date.replace(/-/gi, "/")),
            }));
            const mappedExchangeData = exchangeData.map((data) => ({
              ...data,
              issueDate: new Date(data.issue_date.replace(/-/gi, "/")),
            }));

            setWonPoints(mappedWonData);
            setExchangedPoints(mappedExchangeData);
          }
        }))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStudent]);

  return (
    <div style={{ flexGrow: 1 }}>
      {statusCertificate === ApiStatus.FETCHING && (
        <CustomBackdrop open={true} />
      )}
      <GridContainer container>
        <Grid
          item
          md={5}
          lg={4}
          xs={12}
          className="blue-block"
          style={{
            backgroundImage: "url('./FONDO_RAYAS.png')",
            backgroundColor: "#0C46FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <BoxLogo>
            <Link style={{ cursor: "pointer" }} href="" target="_self">
              <img
                className="logo"
                alt="logo focus"
                src="/LOGO_CFE_FULL.svg"
                width="100%"
              />
            </Link>
            {!loading &&
              (dataCertificate.length > 0 || dataWorkshop.length > 0) && (
                <>
                  <StudentCard data={dataStudent} />
                  <div
                    style={{
                      textAlign: "center",
                      borderRadius: 15,
                      padding: 20,
                      backgroundColor: "white",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <Typography>Tienes acumulado</Typography>
                    <div
                      style={{
                        backgroundColor: "#ef1d7d",
                        color: "#FFF",
                        borderRadius: 20,
                        fontWeight: "600",
                        fontSize: 20,
                        margin: "5px 0px",
                        padding: "5px 0px",
                        width: "auto",
                      }}
                    >
                      {totalPoints} puntos
                    </div>
                    <Link
                      onClick={() => setShowRewardsDetail(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Ver detalle
                    </Link>
                  </div>
                </>
              )}
            <SocialSection />
          </BoxLogo>
        </Grid>
        <GridBody item md={7} lg={8} xs={12}>
          {(dataCertificate.length > 0 || dataWorkshop.length > 0) && (
            <Box
              style={{
                width: "100%",
                padding: 20,
                borderBottom: "1px solid #cecece",
                top: 0,
              }}
            >
              <Button
                type="button"
                style={{ textTransform: "capitalize" }}
                onClick={onAnotherDNIClick}
                color="primary"
              >
                Buscar otro DNI o CE {">"}
              </Button>
            </Box>
          )}
          <BoxBody>
            {showRewardsDetail && (
              <RewardsDetail
                onclick={() => setShowRewardsDetail(false)}
                points={totalPoints}
                wonPoints={wonPoints}
                exchangedPoints={exchangedPoints}
              />
            )}
            {!showRewardsDetail &&
              dataCertificate.length === 0 &&
              dataWorkshop.length === 0 && (
                <>
                  <SearchForm onClick={handleSearchCertificate} />
                  <br />
                  <br />

                  <MoreCoursesOrWorkshops />
                  <br />
                </>
              )}
            {!showRewardsDetail &&
              !loading &&
              dataCertificate &&
              dataWorkshop &&
              (dataCertificate.length > 0 || dataWorkshop.length > 0) && (
                <ResultTab
                  dataCertificate={dataCertificate}
                  dataWorkshop={dataWorkshop}
                />
              )}
            <Footer />
          </BoxBody>
        </GridBody>
      </GridContainer>
    </div>
  );
};
