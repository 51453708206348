import { FC, useState, useEffect, useCallback } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCycleStore } from "../../hooks/useCycleStore";
import { Cycle } from "../../types/slices/cycleType";
import { AddStudentCycleModal } from "./components/AddStudentModal";
import { Student } from "../../types/slices/studentType";
import { UploadExcelModal } from "./components/UploadExcelModal";
import { useStudentStore } from "../../hooks/useStudentStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  { type: "text", field: "dni_student", label: "DNI" },
  {
    type: "text",
    field: "name_student",
    label: "NOMBRE",
    format: (row) => row.name_student + " " + row.surname_student,
  },
  { type: "input", field: "nota", label: "NOTA" },
  { type: "checkbox", field: "status_certificate", label: "DIGITAL" },
  { type: "checkbox", field: "print_certificate", label: "FISICO" },
  {
    type: "text",
    field: "sent_print",
    label: "ENVIADO",
    format: (row) =>
      row.print_certificate === "1"
        ? row.sent_print === "1"
          ? "Si"
          : "No"
        : "",
  },
  { type: "multi-select", field: "", label: "" },
];

const StudentCycle: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Student>({} as Student);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [openModalExcel, setOpenModalExcel] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [studentsByCycleData, setStudentsByCycleData] = useState<Student[]>([]);
  const [filterBy, setFilterBy] = useState<string>("all");
  const {
    status,
    statusStudents,
    studentByCycle,
    selectedCycle,
    openModal,
    getStudentByCycle,
    getStudentByCycleSearch,
    getTemplateStudentByCycle,
    setOpenModal,
    changeIsEdit,
    setSelectedCycle,
    deleteCycleStudent,
    fetchStudentByCycle,
    editStudentCycleStore,
  } = useCycleStore();

  const { getCertificatePDFMultipleTemplate } = useStudentStore();

  useEffect(() => {
    if (selectedCycle.id) {
      setLoading(true);
      getStudentByCycle(selectedCycle.id).then((data) => {
        setStudentsByCycleData(data);
        setLoading(false);
      });
    } else {
      navigate(RoutesMap.CYCLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCycle, setLoading]);

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    let idsStudent = JSON.stringify([rowSelected?.id_student]);

    const response = await deleteCycleStudent(
      selectedCycle?.id || 0,
      idsStudent
    );
    if (response === true) {
      // setSelected({} as Module);
      setModalDeleteConfirm(false);
      getStudentByCycle(selectedCycle.id || 0).then((data) =>
        setStudentsByCycleData(data)
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goBack = () => {
    setSelectedCycle({} as Cycle);
    navigate(RoutesMap.CYCLE);
  };

  const onChange = (value, updatedRow, field) => {
    const rowIndex = studentsByCycleData.findIndex(
      (row) => row.id === updatedRow.id
    );

    if (rowIndex !== -1) {
      const updatedStudentByCycle: any = [...studentsByCycleData];

      updatedStudentByCycle[rowIndex] = {
        ...updatedStudentByCycle[rowIndex],
        [field]: value,
      };

      setStudentsByCycleData(updatedStudentByCycle);
      fetchStudentByCycle(updatedStudentByCycle);
    }
  };

  const onClear = () => {
    if (!isSearch) {
      getStudentByCycle(selectedCycle.id || 0).then((data) =>
        setStudentsByCycleData(data)
      );
    }
  };

  const onSave = async () => {
    const rowFind = studentsByCycleData.find(
      (item) => item.id === rowSelected.id
    );
    await editStudentCycleStore(rowFind?.id || 0, {
      nota: rowFind?.nota,
      status_certificate: rowFind?.status_certificate,
      print_certificate: rowFind?.print_certificate,
    });
    onClear();
    setRowSelected({} as Student);
  };

  const onDownloadExcel = async () => {
    const response: any = await getTemplateStudentByCycle(
      selectedCycle.id || 0
    );
    if (response) {
      const btn_excel = document.createElement("a");
      btn_excel.href = `${process.env.REACT_APP_ROOT_URL}/${response}`;
      document.body.appendChild(btn_excel);
      btn_excel.click();
      document.body.removeChild(btn_excel);
    }
  };

  const onDownloadTemplate = useCallback(() => {
    window.open("/plantilla-certificado.pdf", "_blank");
  }, []);

  const onUploadExcel = async () => {
    setOpenModalExcel(true);
  };

  const onSearch = (value) => {
    if (value.length >= 3) {
      setIsSearch(true);
      getStudentByCycleSearch(selectedCycle?.id || 0, value).then((data) =>
        setStudentsByCycleData(data)
      );
    }
    if (value.length === 0) {
      setIsSearch(false);
      getStudentByCycle(selectedCycle?.id || 0).then((data) =>
        setStudentsByCycleData(data)
      );
    }
  };

  const onPrintMultipleCertificate = async (side: string, type: string) => {
    try {
      if (!selectedRows.length) {
        return CustomSnackbar("error", "Elija al menos un certificado");
      }
      const response = await getCertificatePDFMultipleTemplate(
        selectedCycle?.id,
        selectedRows.map(({ id_certificate }) => id_certificate),
        type,
        side
      );

      const url = window.URL.createObjectURL(response);
      const win = window.open(url, "_blank");
      win?.focus();
      URL.revokeObjectURL(url);
      getStudentByCycle(selectedCycle.id || 0).then((data) =>
        setStudentsByCycleData(data)
      );
    } catch (e) {
      console.warn("No se pudo descargar. Volver a intentar.");
    }
  };

  const onPrintCertificate = async (rowSelected) => {
    try {
      const response = await getCertificatePDFMultipleTemplate(
        selectedCycle?.id,
        [rowSelected?.id_certificate],
        "format"
      );
      const url = window.URL.createObjectURL(response);
      const win = window.open(url, "_blank");
      win?.focus();
      URL.revokeObjectURL(url);
      onClear();
    } catch (e) {
      console.warn("No se pudo descargar. Volver a intentar.");
    }
  };

  const onPrintCertificateChange = useCallback(
    (value, row) => {
      if (value) {
        setSelectedRows((_: any) => [..._, row]);
      } else {
        setSelectedRows((rows) => {
          const rowIndex = rows.findIndex(
            (_: any) => _.id_certificate === row.id_certificate
          );
          if (rowIndex >= 0) {
            rows.splice(rowIndex, 1);
          }
          return [...rows];
        });
      }
    },
    [setSelectedRows]
  );

  const filterBySentPrint = useCallback(
    (value) => {
      const filtered = studentByCycle.filter(
        ({ sent_print, print_certificate }) =>
          value === "all" ||
          (value === "print" && print_certificate === "1") ||
          (sent_print === value && print_certificate === "1")
      );
      setFilterBy(value);
      setStudentsByCycleData(filtered);
      setPage(0);
    },
    [studentByCycle, setFilterBy, setStudentsByCycleData, setPage]
  );

  const onCheckAllToPrint = useCallback(
    (value) => {
      if (value) {
        const selectedToPrint = studentsByCycleData.filter(
          ({ print_certificate }) => print_certificate === "1"
        );
        setSelectedRows(selectedToPrint);
      } else {
        setSelectedRows([]);
      }
    },
    [studentsByCycleData, setSelectedRows]
  );

  const getStudentByCycleCallback = useCallback(
    (value) => {
      getStudentByCycle(value).then((data) => setStudentsByCycleData(data));
    },
    [getStudentByCycle, setStudentsByCycleData]
  );

  return (
    <>
      {statusStudents === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{ marginLeft: "-25px" }}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="view"
          onClick={() => {
            goBack();
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography>Volver</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          isCertificate
          isMultipleEnabled={!Boolean(selectedRows.length)}
          title={
            selectedCycle
              ? `Estudiantes de ${selectedCycle?.name} - ${selectedCycle.courses?.name}`
              : "Estudiantes del Ciclo"
          }
          columns={columns}
          loading={loading}
          rows={studentsByCycleData}
          onRowClick={() => {}}
          hasOptions
          onDelete={onDelete}
          onAddFn={onAdd}
          onSearch={onSearch}
          onDownloadExcel={onDownloadExcel}
          onUploadExcel={onUploadExcel}
          onChangeInput={onChange}
          filterBy={filterBy}
          filterBySentPrint={filterBySentPrint}
          onPrintCertificate={onPrintCertificate}
          onPrintCertificateChange={onPrintCertificateChange}
          onPrintMultipleCertificate={onPrintMultipleCertificate}
          onDownloadTemplate={onDownloadTemplate}
          onSwitch
          editable
          onCheckAllToPrint={onCheckAllToPrint}
          selectedRows={selectedRows}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          setSelectedRows={setSelectedRows}
          onClear={onClear}
          onSave={onSave}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={statusStudents}
        />
      )}
      {openModalExcel && (
        <UploadExcelModal
          openModal={openModalExcel}
          setOpenModal={setOpenModalExcel}
          selectedCycle={selectedCycle}
          getStudentByCycle={getStudentByCycleCallback}
        />
      )}
      {openModal && (
        <AddStudentCycleModal onAddStudent={setStudentsByCycleData} />
      )}
    </>
  );
};

export default StudentCycle;
