import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Reward, rewardType } from "../../types/slices/rewardType";

const initialState: rewardType = {
  statusRewards: ApiStatus.FETCHED,
  rewards: [],
  errorMessage: undefined,
  editReward: {} as Reward,
  selectedReward: {} as Reward,
  isEdit: false,
  openModal: false,
};

const rewardSlice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    onFetchRewards(state, { payload }: { payload: Reward[] }) {
      state.statusRewards = ApiStatus.FETCHED;
      state.rewards = payload;
      state.errorMessage = undefined;
    },
    changeStatusRewards(state, { payload }: { payload: ApiStatus }) {
      state.statusRewards = payload;
    },
    onSetEditReward(state, { payload }: { payload: Reward }) {
      state.editReward = payload;
    },
    onSetSelectedReward(state, { payload }: { payload: Reward }) {
      state.selectedReward = payload;
    },
    onSetOpenModal(state, { payload }: { payload: boolean }) {
      state.openModal = payload;
    },
    onChangeIsEdit(state, { payload }: { payload: boolean }) {
      state.isEdit = payload;
    },
  },
});

export const selectRewardState = (state: RootState) => state.rewardSlice;
export default rewardSlice.reducer;

export const {
  onFetchRewards,
  changeStatusRewards,
  onSetEditReward,
  onSetSelectedReward,
  onSetOpenModal,
  onChangeIsEdit,
} = rewardSlice.actions;
