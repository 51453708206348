import { FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCourseStore } from "../../../hooks/useCourseStore";
import { Module } from "../../../types/slices/coursesType";

export const CreateEditModuleModal: React.FC<any> = (): JSX.Element | any => {
  const {
    editModule,
    openModal,
    setOpenModal,
    isEdit,
    selectedCourse,
    createModuleStore,
    editModuleStore,
    getModulesByCourse,
  } = useCourseStore();

  const [data, setData] = useState<Module>({
    name: "",
    idcourse: 0,
  });

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.name) errors.name = "Nombre es requerido";
    if (values.name && values.name.length > 100)
      errors.name = "El número máximo de caracteres es 100";

    return errors;
  };

  const onSubmit = async (values) => {
    const method = isEdit
      ? editModuleStore(editModule?.id || 0, values)
      : createModuleStore({ ...values, idcourse: selectedCourse.id });
    const response = await method;
    if (response === true) {
      getModulesByCourse(selectedCourse.id);
      setData({
        name: "",
        idcourse: 0,
      });
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setData(editModule);
    } else {
      setData({
        name: "",
        idcourse: 0,
      });
    }
  }, [isEdit, editModule]);

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Módulo" : "Crear Módulo"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditStudent}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Nombre:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
