import { api } from "./configs/axiosConfigs";

export const WorkshopCycleAPI = {
  //ciclos
  getWorkshopCycle: async () => {
    const response = await api
      .get("/workshopCycle")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getTeacher: async () => {
    const response = await api
      .get("/teacher")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getProgram: async () => {
    const response = await api
      .get("/program")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createWorkshopCycle: async (data: any) => {
    const response = await api
      .post("/workshopCycle", { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editWorkshopCycle: async (id: number, data: any) => {
    const response = await api
      .patch(`/workshopCycle/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteWorkshopCycle: async (id: number) => {
    const response = await api
      .delete(`/workshopCycle/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },

  //detalle ciclo
  getStudentByWorkshopCycle: async (idcycle) => {
    const response = await api
      .get(`/detailWorkshopCycleStudent/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getStudentByWorkshopCycleSearch: async (idcycle, term) => {
    const response = await api
      .get(`/detailWorkshopCycleStudent/${idcycle}?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getStudentNotInWorkshopCycle: async (idcycle) => {
    const response = await api
      .get(`/detailWorkshopCycleStudent/notStudent/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createRelationStudentWorkshopCycle: async (id: number, data: any) => {
    const response = await api
      .get(`/detailWorkshopCycleStudent/relation/${id}?ids_student=${data}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editStudentWorkshopCycle: async (id: number, data: any) => {
    const response = await api
      .patch(`/detailWorkshopCycleStudent/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteStudentWorkshopCycle: async (idcycle: number, idstudents: any) => {
    const response = await api
      .delete(
        `/detailWorkshopCycleStudent/${idcycle}?ids_student=${idstudents}&status=2`
      )
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },

  getTemplateStudentByWorkshopCycle: async (idcycle) => {
    const response = await api
      .post(`/detailWorkshopCycleStudent/generatePlantilla/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  uploadTemplateStudentByWorkshopCycle: async (idcycle, data) => {
    const response = await api
      .post(`/detailWorkshopCycleStudent/uploadPlantilla/${idcycle}`, data)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
};
