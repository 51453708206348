import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useMemo, useState } from "react";
import { format } from "date-fns";
import { RewardStudent } from "../../../types/slices/rewardStudentType";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import CountUp from "react-countup";

const RewardCardType = ({
  title,
  items,
  variant,
}: {
  title: string;
  items: (string | ReactNode)[];
  variant?: string;
}) => {
  const itemsList = items.map((item, idx) => (
    <li key={`${idx}-item`}>
      <Typography variant="body2" component="div">
        {item}
      </Typography>
    </li>
  ));

  const ItemsView = (() => {
    if (variant === "ol") {
      return <ol>{itemsList}</ol>;
    }

    return <ul>{itemsList}</ul>;
  })();

  return (
    <div
      style={{
        border: "2px solid #133fe7",
        borderRadius: 20,
        padding: "18px 24px",
        marginBottom: 20,
      }}
    >
      <Typography
        variant="subtitle1"
        component="h6"
        style={{ fontWeight: 600, color: "#133fe7" }}
      >
        {title}
      </Typography>
      {ItemsView}
    </div>
  );
};

const RewardsDetail: FC<{
  onclick: () => void;
  points: number;
  wonPoints: RewardStudent[];
  exchangedPoints: RewardStudent[];
}> = ({ onclick, points, wonPoints, exchangedPoints }) => {
  const [showAllPoints, setShowAllPoints] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setShowAllPoints(open);
    };

  const hadPoints = useMemo(
    () => wonPoints.length > 0 && points === 0,
    [wonPoints, points]
  );

  return (
    <>
      <Realistic autorun={{ speed: 1, duration: 10 }} />
      <div style={{ textAlign: "left", width: "80%", marginBottom: 20 }}>
        <Button
          type="button"
          onClick={onclick}
          color="primary"
          style={{ textTransform: "capitalize" }}
        >
          {"<"} Volver
        </Button>
      </div>
      <div style={{ width: "80%" }}>
        <Typography component="h5" variant="h6">
          Detalle de Puntos
        </Typography>
        <div
          style={{ backgroundColor: "#f5f9fa", padding: 18, borderRadius: 20 }}
        >
          <Stack direction="row" spacing={2}>
            <img
              alt="constancia"
              src="./icons/save-pork-icon.svg"
              width={60}
            ></img>
            <Box>
              Total acumulado
              <Typography
                variant="h4"
                component="h4"
                style={{ fontSize: 30, fontWeight: 600, color: "#133fe7" }}
              >
                <CountUp end={points} /> puntos
              </Typography>
              <div style={{ marginBottom: 5 }}>
                {Boolean(points) && (
                  <>
                    tus puntos se vencerán el 31/12/{new Date().getFullYear()}
                  </>
                )}
                {points === 0 && (
                  <>
                    {hadPoints ? (
                      <Stack direction="row" gap={1}>
                        <CelebrationOutlinedIcon sx={{ color: "orange" }} />
                        <>
                          Felicitaciones, canjeaste todos tu puntos. ¡Sigue
                          acumulando!
                        </>
                      </Stack>
                    ) : (
                      <Stack direction="row" gap={1}>
                        <InfoOutlinedIcon sx={{ color: "blue" }} />
                        <>
                          Revisa cómo acumular puntos y canjearlos. ¡No te los
                          pierdas!
                        </>
                      </Stack>
                    )}
                  </>
                )}
              </div>
              {wonPoints.length > 0 && (
                <Link href="#" onClick={toggleDrawer(true)}>
                  Revisa todos los puntos
                </Link>
              )}
            </Box>
          </Stack>
        </div>
      </div>
      <div style={{ width: "80%", marginTop: 20 }}>
        <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
          ¿Cómo acumulo y uso mis puntos?
        </Typography>
        <RewardCardType
          title="Acumula puntos:"
          items={[
            <>
              <strong>30 puntos</strong> acumulados de{" "}
              <strong>01 Constancia Digital</strong>
            </>,
            <>
              <strong>50 puntos</strong> acumulados de{" "}
              <strong>01 Constancia Física</strong>
            </>,
            <>
              <strong>80 puntos</strong> acumulados de{" "}
              <strong>01 Certificado Digital</strong>
            </>,
            <>
              <strong>100 puntos</strong> acumulados de{" "}
              <strong>01 Certificado Físico</strong>
            </>,
          ]}
        />
        <RewardCardType
          title="Convierte tus puntos en soles:"
          items={[
            <>
              100 puntos = <strong>S/ 1.00</strong>
            </>,
            <>
              Mínimo de canje: <strong>500 puntos (S/ 5.00)</strong>
            </>,
            <>
              Puedes usarlo para descuentos en{" "}
              <strong>certificaciones, constancias, envíos</strong> ó recibir
              dinero directo a tu <strong>Yape ó Plin</strong>
            </>,
          ]}
        />
        <RewardCardType
          title="Premios para canjear con Certificados y Constancias:"
          items={[
            <>
              <strong>440 puntos</strong>, obtienes{" "}
              <strong>01 Constancia Digital</strong>
            </>,
            <>
              <strong>680 puntos</strong>, obtienes{" "}
              <strong>01 Certificado Digital</strong>
            </>,
            <>
              <strong>780 puntos</strong>, obtienes{" "}
              <strong>01 Certificado Físico + Digital</strong>
            </>,
            <>
              <strong>950 puntos</strong>, obtienes{" "}
              <strong>
                01 Certificado Físico + Digital + Constancia Digital
              </strong>
            </>,
            <>
              <strong>1150 puntos</strong>, obtienes{" "}
              <strong>
                01 Certificado Físico + Digital + Constancia Física
              </strong>
            </>,
          ]}
        />

        <RewardCardType
          title="Premios para canjear con dinero:"
          items={[
            <>
              <strong>500 puntos</strong>, obtienes <strong>S/ 5.00</strong>
            </>,
            <>
              <strong>800 puntos</strong>, obtienes <strong>S/ 10.00</strong>
            </>,
            <>
              <strong>1000 puntos</strong>, obtienes <strong>S/ 15.00</strong>
            </>,
            <>
              <strong>1200 puntos</strong>, obtienes <strong>S/ 20.00</strong>
            </>,
          ]}
        />
      </div>
      <div style={{ width: "80%", marginTop: 20 }}>
        <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
          Términos y Condiciones
        </Typography>
        <RewardCardType
          title=""
          variant="ol"
          items={[
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Acumulación de Puntos:
              </Typography>{" "}
              Los puntos se otorgan automáticamente en la plataforma por la
              adquisición de constancias y certificados, siempre y cuando el
              estudiante apruebe o cumpla con el reglamento establecido.
              <br />
              <br />
            </>,
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Canje Mínimo:
              </Typography>{" "}
              El canje de dinero solo es posible a partir de{" "}
              <strong>500 puntos (S/5.00)</strong> y se realizará a través de{" "}
              <strong>Yape o Plin.</strong>
              <br />
              <br />
            </>,
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Validez de Puntos:
              </Typography>{" "}
              Los puntos acumulados se mantendrán vigentes hasta que sean
              canjeados por algún premio. Una vez canjeados, el saldo se
              reiniciará automáticamente y continuará acumulándose hasta
              alcanzar la cantidad necesaria para un nuevo canje.
              <br />
              <br />
            </>,
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Uso de Beneficios:
              </Typography>{" "}
              Los puntos solo pueden ser canjeados por{" "}
              <strong>certificaciones, constancias o dinero</strong>, siempre
              que el estudiante apruebe o cumpla con el reglamento establecido.
              Los puntos no son transferibles a otra persona que estudie o haya
              estudiado en nuestra institución.
              <br />
              <br />
            </>,
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Modificaciones:
              </Typography>{" "}
              La institución se reserva el derecho de modificar los términos del
              programa en cualquier momento, informando previamente a los
              participantes.
              <br />
              <br />
            </>,
            <>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: 600 }}
              >
                Nota importante:
              </Typography>{" "}
              Los puntos se acumulan anualmente y se reinician a cero al
              comenzar cada nuevo año. El sistema de puntos será válido a partir
              del <strong>19 de marzo del 2025</strong>.
            </>,
          ]}
        />
      </div>
      <Drawer anchor="right" open={showAllPoints} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
            width: 500,
            padding: 5,
          }}
          role="presentation"
        >
          <Box onClick={toggleDrawer(false)}>
            <CancelOutlinedIcon
              sx={{
                float: "right",
                color: "gray",
                fontSize: 30,
                cursor: "pointer",
              }}
            />
          </Box>
          <Typography component="h5" variant="h6">
            Tus puntos acumulados
          </Typography>
          <br />
          <Box sx={{ width: "100%", color: "gray" }}>
            <Grid
              container
              spacing={{ xs: 1, md: 3 }}
              columns={{ xs: 6, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                Emisión
              </Grid>
              <Grid item xs={3} sm={5} md={5}>
                Tipo
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                Puntos
              </Grid>
            </Grid>
            {wonPoints.map(({ issueDate, concept, points }, index) => (
              <div key={`grid-${index}`}>
                <Divider style={{ borderStyle: "dashed", margin: "10px 0" }} />
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 6, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    {format(issueDate, "dd/MM/yyyy")}
                  </Grid>
                  <Grid item xs={3} sm={5} md={5}>
                    {concept}
                  </Grid>
                  <Grid item xs={1} sm={3} md={3}>
                    {points}
                  </Grid>
                </Grid>
              </div>
            ))}
          </Box>
          <br />
          <br />
          {!!exchangedPoints.length && (
            <>
              <Typography component="h5" variant="h6">
                Tus puntos canjeados
              </Typography>
              <br />
              <Box sx={{ width: "100%", color: "gray" }}>
                <Grid
                  container
                  spacing={{ xs: 1, md: 3 }}
                  columns={{ xs: 6, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    Emisión
                  </Grid>
                  <Grid item xs={3} sm={5} md={5}></Grid>
                  <Grid item xs={1} sm={3} md={3}>
                    Puntos
                  </Grid>
                </Grid>
                {exchangedPoints.map(({ issueDate, points }, index) => (
                  <div key={`grid-${index}`}>
                    <Divider
                      style={{ borderStyle: "dashed", margin: "10px 0" }}
                    />
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 6, sm: 8, md: 12 }}
                    >
                      <Grid item xs={2} sm={4} md={4}>
                        {format(issueDate, "dd/MM/yyyy")}
                      </Grid>
                      <Grid item xs={3} sm={5} md={5}></Grid>
                      <Grid item xs={1} sm={3} md={3}>
                        {points}
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Box>
              <br />
              <br />
            </>
          )}
          <div
            style={{
              backgroundColor: "#f5f9fa",
              padding: 18,
              borderRadius: 20,
            }}
          >
            <div
              style={{
                float: "left",
                height: 150,
                marginRight: 18,
                paddingTop: 10,
              }}
            >
              <img
                alt="constancia"
                src="./icons/hand-money-icon.svg"
                width={60}
              ></img>
            </div>
            <Typography
              variant="h4"
              component="h4"
              style={{ fontSize: 16, fontWeight: 600, color: "#133fe7" }}
            >
              ¡Canjea tus puntos hoy y no las pierdas!
            </Typography>
            <div style={{ fontSize: 14, marginTop: 5 }}>
              Sigue capacitandote en Focus y aumenta tus posibilidades de
              canjearlos.
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default RewardsDetail;
